
export const UserList = ()=>{

    const FilterUsers = () => {
        return (
            {
                search:"",
                selectedGroup:"0",
                searchFake:"",
                type:"",
                loading:true,
                Authorized:false,
                passwordModal:false,
                pickedOneToChange:0
            }
        )
    }

    const paginationProps=()=>{
        return(
            {
                desc:'asc',
                order:0,
                pageSize:10,
                columnKey:0,
                current:1,
                id:[],
                idLive:0,
                loading:false,
                Authorized:false
            }
        )
    }

    const showDivsConditions:any = () =>{
        return({
            elementSelected: 0,
            addUser: false,
            editPassword : false,
        })
    }

    const ProfileConstraintsData:() => {
        id:string
        type:string;
        channels:string,
        groupe:string,
        check:boolean,
        password: string;
        confirmPassword: string;
        firstname: string;
        lastname: string;
        username:string;
        email: string;
        phone: string;
        mobile: string;
         adresse: string;
        description: string;
        connectionType:number;
        city:string;
        society:string;
        numberPerson:number;
        postalCode:string;
    } = () => {
        return (
            {   id:"",
                type:"",
                channels:"",
                groupe:"",
                check:false,
                password: "",
                confirmPassword: "",
                firstname: "",
                lastname: "",
                username: "",
                email: "",
                phone:"",
                mobile:"",
                 adresse: "",
                description:"",
                connectionType:0,
                city:"",
                society:"",
                numberPerson:0,
                postalCode:""
            }
        )
    }

    const EditPasswordConstraintsData:() => {
        oldPassword:string;
        newPassword:string,
        confirmPassword:string,
    } = () => {
        return (
            {
                oldPassword:"",
                newPassword:"",
                confirmPassword:"",
               
            }
        )
    }


    return({
        FilterUsers,
        paginationProps,
        showDivsConditions,
        ProfileConstraintsData,
        EditPasswordConstraintsData
    })

}