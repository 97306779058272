import gql from "graphql-tag";



export const Add_IP = gql`
    mutation($input:CreateIpInput!){
        createIp(input:$input){
            id
            ip
            description
        }
    }
`
export const Edit_IP = gql`
    mutation($input:UpdateIpInput!){
        updateIp(input:$input){
            id
            ip
            description
        }
    }
`
export const Delete_IP = gql`
    mutation($ids:[Int]){
        DeleteMultipleIp(ids:$ids){
            code
            deleteditems
            undeleteditems
        }
    }
`
export const Get_allData = gql`
    mutation($where:IpWhereInput $pagination: Pagination! $orderBy: IpOrder){
        ips(where:$where pagination:$pagination orderBy: $orderBy ){
            totalCount
            edges{
            node{
                id
                ip
                description
            }
            }
        }
    }
`