
export const IpList = () =>{

    const FilterIP = () => {
        return (
            {
                search: "",
                searchFake: "",
                type: "",
            }
        )
    }

    const paginationPropsIP=()=>{
        return(
            {
                Authorized:false,
                Loading:true,
                totalCount:0,
                order:"ID",
                desc:"DESC",
                pageSize:10,
                columnKey:0,
                current:1,
                id:[],
                idLive:0,
            }
        )
    }

    const showDivsConditionsIP = () =>{
        return({
            action:false,
            elementsSelected:[],
            addIP: false,
            editIP: false
        })
    }
    const AddIPConstraintsData = () => {
        return (
            {
                ipAdress:"",
                descIP:"",
                Id:""
            }
        )
    }

    return({
        FilterIP,
        paginationPropsIP,
        showDivsConditionsIP,
        AddIPConstraintsData
    })

}