import {UserList} from "./userConstants";
const {paginationProps , showDivsConditions , ProfileConstraintsData , EditPasswordConstraintsData , FilterUsers}=UserList()

const UserListINITIALSTATE = {
    ListUsers : [],
    listUsersProfile:[],
    filterUsers:FilterUsers(),
    paginationProps:paginationProps(),
    showDivsConditions:showDivsConditions(),
    profileConstraintsData:ProfileConstraintsData(),
    editPasswordConstraintsData : EditPasswordConstraintsData(),
    selectedUsers:[],
    paginatedUsers:[],
    totalRecords:0,
    loading:false,
    vignette:"",
    codePassword:"",
    url:"",
    isBack:false,
    showModalCode:false,
    coreRightApiCalled:false,
    errorCode:"",
    btnPasswordClicked:false,
    btnAddEditClicked:false,
    errorUsername:false,
    errorEmail:false,
    errorPassword:false,
    errorUpload:''
}

export const UserReducer = (state=UserListINITIALSTATE , action:any)=>{

    switch (action.type){
        case "GET_USERS_DATA":
            return {
                ...state,
                listUsersProfile:action.payload.data
            }
        case "SET_ShowUsers":
            return{
                ...state,
                ListUsers: action.payload
            }
        case "SET_FilterVideos" :
            const {FilterUsersNameChange, FilterUsersValueChange} = action.payload
            return {
                ...state,
                filterUsers: {...state.filterUsers, [FilterUsersNameChange]: FilterUsersValueChange}
            }
        case "SET_PaginationProps" :
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload
            return {
                ...state,
                paginationProps: {...state.paginationProps, [PaginationPropsNameChange]: PaginationPropsValueChange}
            }
        case "SET_PaginationPropsValue" :
            return {
                ...state,
                paginationProps:{...state.paginationProps, ...action.payload}
            }
        case "SET_showDivsConditions":
            const {showDivsConditionsName,showDivsConditionsValue}=action.payload
            const showDivsConditionsObj = {...state.showDivsConditions,[showDivsConditionsName]: showDivsConditionsValue}
            return{
                ...state,
                showDivsConditions:showDivsConditionsObj
            }
        case "SET_ProfileActions" :
            const {profileConstraintsDataNameChange,profileConstraintsDataValueChange}=action.payload
            return {
                ...state,
                profileConstraintsData:{...state.profileConstraintsData, [profileConstraintsDataNameChange]:profileConstraintsDataValueChange}
            }
        case "SET_EditPasswordActions" :
            const {editPasswordDataNameChange,editPasswordDataValueChange}=action.payload
            return {
                ...state,
                editPasswordConstraintsData:{...state.editPasswordConstraintsData, [editPasswordDataNameChange]:editPasswordDataValueChange}
            }
        
            case "ADD_USER":
                return {
                    ...state,
                    listUsersProfile: [...state.listUsersProfile, action.payload ]
                }
                
            case "SET_SELECTED_USERS":
                    return {
                        ...state,
                        selectedUsers: action.payload
                    }    
            case "GET_USERS_DATA_PAGINATED":
                return {
                    ...state,
                    paginatedUsers:action.payload.data
                }     
                case "SET_TOTAL_RECORDS":
                    return {
                        ...state,
                        totalRecords:action.payload
                    }          
                case "SET_LOADING":
                    return {
                        ...state,
                        loading:action.payload
                    }    
                case "SET_VIGNETTE":
                    return {
                     ...state,
                     vignette:action.payload
                     }    
                case "SET_URL":
                    return {
                      ...state,
                     url:action.payload
                    }            
                case "SET_BACK":
                    return {
                      ...state,
                      isBack:action.payload
                        }  
                case "SHOW_MODAL_CODE":
                            return {
                                ...state,
                                showModalCode:action.payload
                            }            
                case "SET_CODE":
                         return {
                         ...state,
                        codePassword:action.payload
                     }    
                     case "SET_CORE_RIGHT_API":
                        return {
                            ...state,
                            coreRightApiCalled:action.payload
                        }         
                        case "SET_ERROR_CODE":
                            return {
                                ...state,
                                errorCode:action.payload
                            }      
             case "SET_BTN_PASSWORD":
                     return {
                        ...state,
                        btnPasswordClicked:action.payload
                }      
                case "SET_BTN_ADD_EDIT_USER":
                    return {
                       ...state,
                       btnAddEditClicked:action.payload
               }    
               case "SET_ERROR_EMAIL":
                return {
                   ...state,
                   errorEmail:action.payload
           }    
           case "SET_ERROR_USERNAME":
            return {
               ...state,
               errorUsername:action.payload
       }    
       case "SET_ERROR_PASSWORD":
        return {
           ...state,
           errorPassword:action.payload
   }                                     
           
   case "SET_ERROR_UPLOAD":
    return {
        ...state,
        errorUpload:action.payload
    }     
                    
    
        default: {
            return state
        }
    }

}
