import React, { useEffect, useState,useLayoutEffect } from "react";
import "./group.scss";
import '../ip/ip.scss';
import "../user/user.scss";
import GroupHeader from "./components/groupHeader";
import GroupTable from "./components/List/groupeTable";
import AddEditGroup from "./components/AddEdit/addEdit";
import Permission from "./components/permission/permission";
import { Hooks } from "./utils/hooks";
import { Grid } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getUserData } from "../../userData/action";
import {useLocation} from 'react-router-dom'
import i18next from 'i18next';
import LoadingScreen from "../ip/components/loadingScreen"
import Notfound from "../ip/components/Notfound";
import {Loader} from "@empreinte/components.loader"
import { setGroupsToDelete } from "./store/groupActions";
import { useDebounce } from "../../utils/use-debounce";
const Group = () => {
  
  const [searchText, setSearchText] = useState("");
  const debouncedSearchQuery = useDebounce(searchText, 600);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loading = useAppSelector((state:any) => state.GroupeReducer.showDivsConditionsGroup.Loading)
  const ListTable = useAppSelector((state:any) => state.GroupeReducer.List.data);
  const auth = useAppSelector((state:any)=> state.GroupeReducer.showDivsConditionsGroup.Authorized)
  const loadingPermission = useAppSelector((state:any)=> state.GroupeReducer.showDivsConditionsGroup.loading)



  const location = useLocation();
  const queryParam = new URLSearchParams(location.search)
  const [lang, setLang] = useState(queryParam.get("lang") || (localStorage.getItem("lang") !== "null" && localStorage.getItem("lang")) || "fr")
  const [indexes,setIndexes] = useState([0])
  const showDivCondition = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup
  );
  const {GetGroupsData,GetSelectAdd,usePrevious} = Hooks()
 
  const {
    onValueFormUserchangeGroup,
  } = Hooks();
  const dispatch = useAppDispatch();
  useLayoutEffect(()=>{
    dispatch(getUserData())
  },[])
  const prevSearchText = usePrevious(searchText);
  if(currentPage!=1 && !ListTable?.length )
  {
    setCurrentPage(currentPage-1)
  }
  useEffect(() => { 
    if(auth){
        const offset = (currentPage - 1) * pageSize;
            if (searchText !== "") {
              if(searchText !== prevSearchText){
                setCurrentPage(1)
              }
              GetGroupsData(pageSize,offset,searchText,currentPage)
              GetSelectAdd()
            }else {
              GetGroupsData(pageSize,offset,"",currentPage)
              GetSelectAdd()
            }
          }

},[currentPage, pageSize, debouncedSearchQuery,auth,showDivCondition.action]);

useEffect(()=>{
  window.addEventListener("message", function (event) {
    if (event.data?.info === "updateLang") {
      setLang(event.data.lang)
      i18next.changeLanguage(event.data.lang)
      localStorage.setItem("lang", event.data.lang)
    } 
  });
},[])

  return (
    <>
            {loading ? <LoadingScreen/> : auth? (
                (
    <div className={"divGlobalTable"}>
      {!showDivCondition.addGroup &&
      !showDivCondition.editGroup &&
      !showDivCondition.permissionGroup ? (
        <div>
          <Loader
            isOpen={loadingPermission}
          />
          <GroupHeader />
          <GroupTable 
            searchText={searchText}
            setSearchText={setSearchText}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
           />
        </div>
      ) : !showDivCondition.addGroup && showDivCondition.permissionGroup ? (
        <div>
          <GroupHeader />
          <Permission setIndexes={setIndexes} indexes={indexes} />
        </div>
      ) : (
        <div>
          <Grid>
            <GroupHeader />
            <AddEditGroup />
          </Grid>
        </div>
      )}
    </div>
     )
     ):(
      <Notfound/>
  )}
 </>
  );
};
export default Group;

