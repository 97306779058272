import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {useWindowSize} from './resizeEvent'
export const DataGroupe = () => {
  const { t } = useTranslation();
  const ListTable = useAppSelector((state:any) => state.GroupeReducer.List.data);
  const windowSize = useWindowSize()
    const hideColumn = (size:any) => {
       return  windowSize < size
    };
  const columns:any = [
   
  ];

  const data = {
    totalElements: 22,
    content:
    (typeof ListTable === 'undefined') ?  [] :  ListTable
  };

  return {
    columns,
    data,
  };
};
