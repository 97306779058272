import React, { useEffect, useState } from "react";
import {
  Card,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { InputAdornment, IconButton, Button } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getChannels,
  getGroups,
  getUsersData,
  getValidationCode,
} from "../../../../services/userAPI";
import { editUser } from "../../../../services/userAPI";
import { InputText } from "@empreinte/components.input-text";
import { SelectMultiple } from "@empreinte/components.select-multiple";
import { SelectList } from "@empreinte/components.select-list";
import { setLoading, setProfileActions } from "../../store/userActions";
import {TextArea} from "@empreinte/components.text-area"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SecurityForm = () => {
  const { t } = useTranslation();
  const { profiles_user_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowconfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };
  const [channels, setChannels] = useState<any>();
  const [groups, setGroups] = useState<any>();
  const [selectedGroup, setselectedGroup] = useState<any>();
  const [selectedChannels, setSelectedChannels] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [userPass, setUserPass] = useState<any>(true);
  const [pass, setPass] = useState<any>(false);
  const [tempPass, setTempPass] = useState<any>(false);
  const [ids, setIds] = useState<any>();
  const [passwordType, setPasswordType] = useState<any>("0");
  const [passwordError, setPasswordError] = useState("");

  const dispatch = useAppDispatch();
  const showDivCondition = useAppSelector(
    (state: any) => state.UserReducer.showDivsConditions
  );
  const [errorPass, setErrorPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const groupsData = useAppSelector((state) => state.GroupeReducer.List);
  const [codechecked, setCodeChecked] = useState<any>();
  const {
    handleChangeInputs,
    values,
    onFocus,
    onBlur,
    onSearch,
    onChange,
    handleChangeCheckbox,
    handleChangeGroup,
    handleChangeChannel,
    handleChangeConnectionType,
  } = Hooks();
  useEffect(() => {
    dispatch(setLoading(true));

    getChannels().then((res) => {
      setChannels(res.data.choices);
      dispatch(setLoading(false));
    });

    groupsData && setGroups(groupsData.data);

    values.id &&
      editUser(values.id)
        .then((res: any) => {
          setCurrentUser(res.data);
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "postalCode",
              profileConstraintsDataValueChange: res.data.postalCode,
            })
          );
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "city",
              profileConstraintsDataValueChange: res.data.city,
            })
          );
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "numberPerson",
              profileConstraintsDataValueChange: res.data.numberPerson,
            })
          );
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "society",
              profileConstraintsDataValueChange: res.data.society,
            })
          );
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "adresse",
              profileConstraintsDataValueChange: res.data.adresse,
            })
          );
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "vignette",
              profileConstraintsDataValueChange: res.data.vignette              ,
            })
          );
          
        })
        .catch((err) => console.log(err));
    currentUser && setselectedGroup(currentUser?.group);
  }, []);

  useEffect(() => {
    const regexAtLeastOneNumber = new RegExp(".*[0-9].*");

    if (
      values.password !== values.confirmPassword &&
      values.password.length >= 8 &&
      values.confirmPassword.length >= 8
    ) {
      setPasswordError(t("resetPassword.NoIdenticalPass"));
    } else {
      setPasswordError("");
    }
    if (values.password && values.password.length < 8) {
      setPasswordError("");

      setErrorPass(t("resetPassword.passwordLength"));
    } else if (
      values.password &&
      !regexAtLeastOneNumber.test(values.password)
    ) {
      setPasswordError("");
      setErrorPass(t("resetPassword.oneNumber"));
    } else setErrorPass("");

    if (values.confirmPassword && values.confirmPassword.length < 8) {
      setPasswordError("");

      setErrorConfirmPass(t("resetPassword.passwordConfirmLength"));
    } else if (
      values.confirmPassword &&
      !regexAtLeastOneNumber.test(values.confirmPassword)
    )
      setErrorConfirmPass(t("resetPassword.oneNumber"));
    else setErrorConfirmPass("");
  }, [values.password, values.confirmPassword]);
  let arrKeys: any = [];
  let dataListGroup: any = [];
  groups?.map((group: any) =>
    dataListGroup.push({ label: group?.name, value: group?.id })
  );
  dataListGroup.unshift({ label: `${t("Profile.selectGroup")}`, value: 0 });
  const onChangeTypePassword = (event: any) => {
    handleChangeConnectionType(parseInt(event.target.value));

    if (event.target.value === "0") {
      setUserPass(true);
      setTempPass(false);
      setPass(false);
      setPasswordType(0);
    } else if (event.target.value === "1") {
      setUserPass(false);
      setTempPass(true);
      setPass(false);
      setPasswordType(1);
    } else {
      setUserPass(false);
      setPass(true);
      setTempPass(false);
      setPasswordType(2);
    }
  };
  useEffect(() => {
    setLoading(true);
    showDivCondition.editUser &&
      getValidationCode(values.id)
        .then((res: any) => {
          setCodeChecked(res.data.use_code_validation);
          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "check",
              profileConstraintsDataValueChange: res.data.use_code_validation,
            })
          );
        })
        .catch((err) => console.log("err", err));
    setLoading(false);
  }, []);
  useEffect(() => {
    const channels = currentUser?.channels.map((channel: any) =>
      parseInt(channel.id)
    );
    currentUser?.channels && setSelectedChannels(channels);
    handleChangeChannel(channels);
  }, [currentUser]);
  const dataList =
    channels && Object.entries(channels).map(([label, Id]) => ({ label, Id }));

  const onChangeGroup = (event: any) => {
    if (event.target.value !== "0") {
      let selectedGroup = groups?.filter(
        (group: any) => group.id == event.target.value
      );
      setselectedGroup(selectedGroup[0]);
      handleChangeGroup(selectedGroup[0].id);
    } else handleChangeGroup("0");
  };
  const onChangeChannels = (event: any) => {
    setSelectedChannels(event.target.value);
    handleChangeChannel(event.target.value);
  };

  useEffect(() => {}, [selectedChannels]);
  const passwordTypes = [
    { value: 0, label: `${t("Profile.userPass")}` },
    { value: 1, label: `${t("Profile.tempPass")}` },
    { value: 2, label: `${t("Profile.pass")}` },
  ];


  return (
    <div>
      <Card title={t("Profile.updatePassword")}>
        <div className={"InputDiv"}>
        <FormControl>
            <div className={"InputLablDivSecurity"}>
              <div className="Input">
                <span>{t("Profile.selectChannels")}</span>
                <SelectMultiple
                  listIds={selectedChannels && selectedChannels}
                  dataList={dataList}
                  style={{ marginTop: "5px" }}
                  handleChange={onChangeChannels}
                  placeHolder={t("Profile.selectChannels")}
                ></SelectMultiple>
              </div>
            </div>
          </FormControl>
          <FormControl>
            <div className={"InputLablDivSecurity"}>
              <div className="Input">
                <span>{t("Profile.selectGroup")}</span>
                <div className="required">*</div>
                <SelectList
                  value={values?.group ? values?.group : "0"}
                  data={dataListGroup}
                  handleChange={onChangeGroup}
                  name="group"
                  style={{ marginTop: "5px" }}
                />
              </div>
            </div>
          </FormControl>
          {profiles_user_module?.activate_code_validation_no_api_action
            ?.authorized && (
            <>
              <div className={"generalinfos"} style={{ marginLeft: "15px" }}>
                <span>{t("Profile.updatePassword")}</span>
              </div>
              <div
                has-permission="activate_code_validation_no_api"
                className={"InputLablDiv"}
              >
                <FormGroup>
                  <FormControlLabel
                    className={"checkbox"}
                    value={values.check}
                    onChange={handleChangeCheckbox}
                    control={<Checkbox checked={values.check} />}
                    label={t("Profile.enablePassword")}
                  />
                </FormGroup>
              </div>
            </>
          )}
          {showDivCondition.addUser && (
            <FormControl>
              <div>
                <div className={"InputLablDivSecurity"}>
                  <div className="Input">
                    <span style={{ fontWeight: "bold" }}>Type</span>
                    <div className="required">*</div>

                    <SelectList
                      value={passwordType}
                      data={passwordTypes}
                      className={"formItem3"}
                      name="connectionType"
                      handleChange={onChangeTypePassword}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                </div>
                {userPass ? (
                  <div>
                    {" "}
                    <div className={"InputLablDivSecurity"}>
                      <div className="Input">
                        <span>{t("Profile.password")}</span>
                        <div className="required">*</div>

                        <InputText
                          fullWidth
                          error={errorPass !== "" || passwordError !== ""}
                          value={values.password}
                          style={{ marginTop: "5px" }}
                          type={!showPassword ? "password" : "text"}
                          className={"formItem3"}
                          handleChange={handleChangeInputs}
                          placeholder={t("Profile.password")}
                          name="password"
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  aria-label="Toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {!showPassword ? (
                                    <Visibility />
                                    ) : (
                                    <VisibilityOff />
                                  )}
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        ></InputText>
                        {errorPass !== "" && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {errorPass}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={"InputLablDivSecurity"}>
                      <div className="Input">
                        <span>{t("Profile.confirmPassword")}</span>
                        <div className="required">*</div>
                        <InputText
                          fullWidth
                          error={
                            passwordError !== "" || errorConfirmPass !== ""
                          }
                          value={values.confirmPassword}
                          style={{ width: "100%", marginTop: "5px" }}
                          type={!showconfirmPassword ? "password" : "text"}
                          className={"formItem3"}
                          handleChange={handleChangeInputs}
                          placeholder={t("Profile.password")}
                          name="confirmPassword"
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  aria-label="Toggle password visibility"
                                  onClick={handleClickShowconfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {!showconfirmPassword ? (
                                    <Visibility />
                                    ) : (
                                    <VisibilityOff />
                                  )}
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        ></InputText>
                        {errorConfirmPass !== "" && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {errorConfirmPass}
                          </p>
                        )}

                        {!errorConfirmPass &&
                          !errorPass &&
                          passwordError !== "" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {passwordError}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                ) : pass ? (
                  <h3 style={{ marginLeft: "20px" }}>
                    {t("resetPassword.sendPass")}
                  </h3>
                ) : (
                  <h3 style={{ marginLeft: "20px" }}>
                    {t("resetPassword.sendTempPass")}
                  </h3>
                )}
              </div>
            </FormControl>
          )}
         
        </div>

        {/*Input DIV GLOBAL*/}
      </Card>
    </div>
  );
};

export default SecurityForm;
