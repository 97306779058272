import { TYPES } from "./types";

const initState = {
    userData : {},
    roles:[],
    modules:{
        profiles_user_module:{
            profiles_user_post_add_action:{authorized:false}
        },
        profiles_group_module:{
            profiles_group_post_add_action:{authorized:false}
        },
        profiles_ip_module:{
            profiles_ip_post_add_action:{authorized:false}
        }
    },
    isSuperAdmin:false
}

const ReducerUserData = (state=initState,action)=>{
    switch (action.type) {
        case TYPES.GET_USER_DATA:
            return {
                ...state,
                userData: action.payload.userData.user,
                roles: action.payload.userData.roles,
                modules: action.payload.userData.modules,


            }
        case TYPES.USER_IS_ADMIN: 
            return{
                ...state,
                isSuperAdmin: action.payload
            }
        default:
            return state
    }
}
export default ReducerUserData
