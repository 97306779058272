import React, { StrictMode } from 'react';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import ApolloProvider from "./ApolloProvider";
import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
      <ApolloProvider />
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
