export function setPaginationPropsIP(e:any){
    return{
        type:"SET_PaginationPropsIP",
        payload:e,
    }
}

export function setshowDivsConditionsIP(e:any){    
    return {
        type : "SET_showDivsConditionsGroup",
        payload:e
    }
}

export function setAddIPActions(e:any){
    return{
        type:"SET_AddIPActions",
        payload:e,
    }
}
export function setFilterIpActions(e:any){

    return{
        type:"SET_FilterIP",
        payload:e,
    }

}
export function setIpToDelete(e: any) {
    return {
      type: "SET_DeleteIpsActions",
      payload: e,
    };
  }
