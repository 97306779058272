import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  setPaginationPropsGroup,
  setshowDivsConditionsGroup,
} from "../../store/groupActions";
import UseActionMenu from "../List/actionsUserTable";
import {GridNorows} from "@empreinte/components.grid-norows"
import {Datagrid} from "@empreinte/components.datagrid"
import { useTranslation } from "react-i18next";
import {useWindowSize} from '../../utils/resizeEvent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state:any) => state.GroupeReducer.paginationPropsGroup
  );
  const {profiles_group_module} = useAppSelector(state =>state.ReducerUserData.modules)
  const total = useAppSelector((state:any)=>state.GroupeReducer.List?.recordsFiltered)
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize()
  const hideColumn = (size:any) => {
     return  windowSize < size
  };

  const handleIconClick = (e:any, y:any) => {
  //   dispatch(setPaginationProps({
  //     PaginationPropsNameChange: "order",
  //     PaginationPropsValueChange: e
  //   }))
  //   dispatch(setPaginationProps({
  //   PaginationPropsNameChange: "desc",
  //   PaginationPropsValueChange: y
  // }))
  };
  const updatedColumns = [
 
    {
      headerName: "ID",
      field: "id",
      key: 0,
      width: 100,
      flex:1,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">ID</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: t("Profile.nameGroup"),
      field: "name",
      key: 1,
      width: 320,
      flex:1,
      hide:hideColumn(414),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.nameGroup")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: t("Profile.roles"),
      field: "roles",
      key: 2,
      width: 300,
      flex:1,
      hide:hideColumn(768),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.roles")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: "Description",
      field: "description",
      key: 3,
      width: 700,
      flex:1,
      hide:hideColumn(1008),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">Description</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: "Actions",
      key: "action",
      renderCell: (record: any, action: any) => {
        return <UseActionMenu record={record} />;
      },
      flex:1,
      width: 200,
    },
  ];
  
  const DataTable = ({setCurrentPage,currentPage,setPageSize,pageSize}:any) => (
    <div className="DataTable">
        {!total? <div  className="NorowTable"><GridNorows content={t("Profile.noData")}/> </div> :<Datagrid 
                    rows={dataSource.content && dataSource.content}
                    columns={updatedColumns as any}
                    totalEntities={total && total}
                    selectionModel={selectedRowKeys}
                    checkboxSelect={(id:any)=>{setSelectedRowKeys(id);dispatch(
                              setshowDivsConditionsGroup({
                                    showDivsConditionsName: "elementsSelected",
                                    showDivsConditionsValue: id
                                })
                            )}}
                    EntriesName={`${t("Profile.entries")}`}
                    OfPageName={`${t("Profile.of")}`}
                    ShowName={`${t("Profile.show")}`}
                    height={"calc(100vh - 185px)"}
                    setCurrentPage={setCurrentPage}
                    currentPage={dataSource?.content?.length>0 ? currentPage : currentPage-1 }
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    checkboxSelection
                />}
        </div>
  );

  return {
    DataTable,
    selectedRowKeys,
  };
}

export default useDataTableMedia;
