import React, { useEffect, useState,useRef } from "react";
import { useDispatch } from "react-redux";
import {PostData,GetData,DeleteData,UpdateData} from "../../../ApiCalls"
import {
  setAddGroupActions,
  setshowDivsConditionsGroup,
  restartData,
  setGroupsToDelete
} from "../store/groupActions";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import {GraphQLFetchData} from './GraphQLFetchData'
import { useAppSelector } from "../../../redux/hooks";
export const Hooks = () => {
  const { t } = useTranslation();
  const [visibleMultiDeletePopup, setVisibleMultiDeletePopup] = useState<boolean>(false) 
  const [visible, setVisible] = useState<any>(false);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const dispatch = useDispatch();
  const values = useAppSelector(
    (state:any) => state.GroupeReducer.addGroupConstraintsData
  );
  const selected = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.elementSelected
  );
  const checked = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.actionsSelected
  );

  const {onDeleteGroup} = GraphQLFetchData()


 const setGroupsSelectedEmpty =() => {
  dispatch(
    setshowDivsConditionsGroup({
          showDivsConditionsName: "elementsSelected",
          showDivsConditionsValue: []
      })
  )
 }

    const GetGroupsData = async(length:any,start:any,search:any,draw:any)=>{
      if(search === ""){
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "loading",
            showDivsConditionsValue: true,
          })
        );
      }
      await GetData(`/dataTable/groups?columns%5B0%5D%5Borderable%5D=true&length=${length}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&search%5Bvalue%5D=${search}&start=${start}&draw=${draw}`)
      .then((res) => {
              dispatch({ type: "GET_LIST_PROFILE", payload: { List: res.data ,ListDrop: res.data } });
              dispatch(
                setshowDivsConditionsGroup({
                  showDivsConditionsName: "Loading",
                  showDivsConditionsValue: false,
                })
              );
              dispatch(
                setshowDivsConditionsGroup({
                  showDivsConditionsName: "loading",
                  showDivsConditionsValue: false,
                })
              );
            })
            .catch((err) => {
              console.log( err);
            });
    }


    const editOnePermissionByToggle = async(idPermission:any,auth:any,yesOrNo:any)=>{
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "loading",
          showDivsConditionsValue: true,
        })
      );
      
      let permissions:any = [{
        authorize: yesOrNo,
        idg:selected,
        idp:idPermission,
      }]
      await PostData("/core/rights/right-by-group-and-permission",{permissions})
      .then(res=>{
        getPermissions(selected)
      })
      .catch((err)=>{
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "loading",
            showDivsConditionsValue: false,
          })
        );
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "RulesPermissions",
            showDivsConditionsValue : true
          })
        )
      })
    }

    const editPermissions = async(authorize:any) =>{
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "loading",
          showDivsConditionsValue: true,
        })
      );
      let permissions:any = []
      checked.map((e:any)=>permissions.push({
        authorize:authorize,
        idg:selected,
        idp:e,
      }))
      await PostData("/core/rights/right-by-group-and-permission",{permissions})
      .then(res=>{
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "elementSelected",
            showDivsConditionsValue: "",
          })
        );
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "actionsSelected",
            showDivsConditionsValue: [],
          })
        );
        
        getPermissions(selected)
      })
      .catch((err)=>{
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "loading",
            showDivsConditionsValue: false,
          })
        );
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "RulesPermissions",
            showDivsConditionsValue : true
          })
        )
      })
    }
    const handleCancelMultiDeletePopup = () => {
       setVisibleMultiDeletePopup(false)
    };
    const showModalMultiDeletePopup = () => {
      setVisibleMultiDeletePopup(true)
    }
    const getPermissions = async(idGroup:any)=>{
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "loading",
          showDivsConditionsValue: true,
        })
      );
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "elementSelected",
          showDivsConditionsValue: idGroup,
        })
      );
      await GetData("/core/rights/modules")
      .then((res)=>{
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "allPermissions",
            showDivsConditionsValue: res.data.modules,
          })
        );
      })
      await GetData(`/core/rights/group/${idGroup}/grouped-by-modules`)
      .then((res)=>{
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "permissionGroup",
            showDivsConditionsValue: true,
          })
        );
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "groupPermissions",
            showDivsConditionsValue: res.data,
          })
        );
        dispatch(
          setshowDivsConditionsGroup({
            showDivsConditionsName: "loading",
            showDivsConditionsValue: false,
          })
        );
      })
      .catch((err)=>{
        console.log(err)
      })
    }
   

    const GetSelectAdd = async()=>{
      await GetData("/profiles/groups/roles")
      .then((res) => {
              dispatch({ type: "GET_SELECT_CHOICES", payload: { rolesSelect: res.data.data } });
            })
            .catch((err) => {
              console.log(err);
            });
    }
    const DeleteGroup = async(id:any)=>{
      onDeleteGroup({variables:{ids:id}}).then((data : any)=>{ 
        if(data?.data?.DeleteGroup?.message == 'Deleted Successfully')
       { 
         message.success({ content: t('Profile.successdelete'), duration: 2 }) 
        dispatch(setGroupsToDelete(id))       
      }
      })
      .catch(()=> {dispatch(setGroupsToDelete([])) ;message.error({ content: t('Profile.messageerroroperation'), duration: 2 })});

    }
    const UpdateGroup = async()=>{
      const empProfilesGroupForm ={
        parent : values.parentId,
        id: values.id,
        name : values.name ,
        roles: values.roles , 
        description : values.description 
    }
      await PostData(`/profiles/group/edit/${values.id}`,{empProfilesGroupForm})
      .then((res) => {
        if (res.data.code === 200){
          dispatch(
            setAddGroupActions({
              addGroupConstraintsDataNameChange: "parentId",
              addGroupConstraintsDataValueChange: "",
            })
          );
          dispatch(
            setAddGroupActions({
              addGroupConstraintsDataNameChange: "id",
              addGroupConstraintsDataValueChange: "",
            })
          );
          dispatch(
            setAddGroupActions({
              addGroupConstraintsDataNameChange: "name",
              addGroupConstraintsDataValueChange: "",
            })
          );
          dispatch(
            setAddGroupActions({
              addGroupConstraintsDataNameChange: "roles",
              addGroupConstraintsDataValueChange: "",
            })
          );
          dispatch(
            setAddGroupActions({
              addGroupConstraintsDataNameChange: "description",
              addGroupConstraintsDataValueChange: "",
            })
          );
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "editGroup",
              showDivsConditionsValue: false,
            })
          );
          dispatch(restartData())
            
        }              
      })

    }

    const ADDGroup = async()=>{
      const empProfilesGroupForm ={
                parent : values.parentId,
                name : values.name ,
                roles: values.roles , 
                description : values.description 
            };
      await PostData("/profiles/group/add" , {empProfilesGroupForm})
      .then((res) => {
          if(res.data.code === 400){
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "message",
              showDivsConditionsValue: t("please choose other name because its taken"),
            })
          );
        }
              if (res.data.code === 200){
                dispatch(
                  setAddGroupActions({
                    addGroupConstraintsDataNameChange: "parentId",
                    addGroupConstraintsDataValueChange: "",
                  })
                );
                dispatch(
                  setAddGroupActions({
                    addGroupConstraintsDataNameChange: "id",
                    addGroupConstraintsDataValueChange: "",
                  })
                );
                dispatch(
                  setAddGroupActions({
                    addGroupConstraintsDataNameChange: "name",
                    addGroupConstraintsDataValueChange: "",
                  })
                );
                dispatch(
                  setAddGroupActions({
                    addGroupConstraintsDataNameChange: "roles",
                    addGroupConstraintsDataValueChange: "",
                  })
                );
                dispatch(
                  setAddGroupActions({
                    addGroupConstraintsDataNameChange: "description",
                    addGroupConstraintsDataValueChange: "",
                  })
                );
                dispatch(
                  setshowDivsConditionsGroup({
                    showDivsConditionsName: "addGroup",
                    showDivsConditionsValue: false,
                  })
                );
                dispatch(
                  setshowDivsConditionsGroup({
                    showDivsConditionsName: "message",
                    showDivsConditionsValue: "",
                  })
                );
                dispatch(restartData())
              }              
            }).catch(err=>{
              dispatch(
                setshowDivsConditionsGroup({
                  showDivsConditionsName: "message",
                  showDivsConditionsValue: t("please choose other name because its taken"),
                })
              );
            })
    }



  // ////// ************************* show div conditions **************************************///////
  const handleAddGroup = () => {
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "id",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "name",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "roles",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "description",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "addGroup",
        showDivsConditionsValue: true,
      })
    );
  };

  ///************************************ dispatch ajout groupe **********************//////////
  const handleChangeInputs = (event: any) => {
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: event.target.name,
        addGroupConstraintsDataValueChange: event.target.value,
      })
    );
  };
  const handleChangeSelect = (event: any) => {
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "roles",
        addGroupConstraintsDataValueChange: event.target.value,
      })
    );
  };
  /////////****************************** Form Add Group ******************///////////////////////
  const onValueFormUserchangeGroup = (val: any) => {
    if (val.roleGroup) {
      dispatch(
        setAddGroupActions({
          addGroupConstraintsDataNameChange: "roles",
          addGroupConstraintsDataValueChange: values.roles,
        })
      );
    }
  };



  




  const handleSubmit = () => {
  };
  const handleEdit = () => {
  };
  const handleDelete = () => {
  };
  const onResetEditPassword = () => {
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "addGroup",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "editGroup",
        showDivsConditionsValue: false,
      })
    );
    
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "id",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "name",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "roles",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "description",
        addGroupConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "parentId",
        addGroupConstraintsDataValueChange: "",
      })
    );
  };
  const handleUpdateGroup = (record: any) => {
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "editGroup",
        showDivsConditionsValue: true,
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "id",
        addGroupConstraintsDataValueChange: record.row.id,
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "name",
        addGroupConstraintsDataValueChange: record.row.name,
      })
    );

    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "roles",
        addGroupConstraintsDataValueChange: record.row.roles,
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "description",
        addGroupConstraintsDataValueChange: record.row.description,
      })
    );
    dispatch(
      setAddGroupActions({
        addGroupConstraintsDataNameChange: "parentId",
        addGroupConstraintsDataValueChange: record.row.parent.id,
      })
    );
  };

  const usePrevious = (value:any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleClickPermission = () => {
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "permissionGroup",
        showDivsConditionsValue: true,
      })
    );
  };
  const handleCheckCkeckbox = (eventt: any,soft:any="",move:boolean=false) => {
   

    if(!move){
      let check = checked
    eventt.forEach((event:any)=>{

      if(check.indexOf(event)>=0){
        if(soft === "force"){
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "actionsSelected",
              showDivsConditionsValue: check,
            })
          );
          
        }else{
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "actionsSelected",
              showDivsConditionsValue: check.slice(0,check.indexOf(event)).concat(check.slice(check.indexOf(event)+1,check.length)),
            })
          );
          check = check.slice(0,check.indexOf(event)).concat(check.slice(check.indexOf(event)+1,check.length))
        }
      }else{
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "actionsSelected",
          showDivsConditionsValue: [...check,event],
        })
      );
      check = [...check,event] 
    }
    })
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "selectAll",
        showDivsConditionsValue: false,
      })
    );
    }else{
      dispatch(
        setshowDivsConditionsGroup({
          showDivsConditionsName: "actionsSelected",
          showDivsConditionsValue: [],
        })
      );
    }



    
  };
  const selecAllCheckbox = (event: any) => {
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "selectAll",
        showDivsConditionsValue: event.target.checked,
      })
    );
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "selectOne",
        showDivsConditionsValue: true,
      })
    );
  };
  const onResetPermission = () => {
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "permissionGroup",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: "",
      })
    );
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "actionsSelected",
        showDivsConditionsValue: [],
      })
    );
  };

  return {
    visibleMultiDeletePopup,
    setGroupsSelectedEmpty,
    showModalMultiDeletePopup,
    handleCancelMultiDeletePopup,
    handleAddGroup,
    handleChangeInputs,
    handleChangeSelect,
    values,
    GetGroupsData,
    onResetEditPassword,
    onValueFormUserchangeGroup,
    handleUpdateGroup,
    showModal,
    handleCancel,
    visible,
    handleClickPermission,
    onResetPermission,
    selecAllCheckbox,
    checkedItems,
    handleCheckCkeckbox,
    handleSubmit,
    handleEdit,
    GetSelectAdd,
    ADDGroup,
    UpdateGroup,
    DeleteGroup,
    getPermissions,
    editPermissions,
    editOnePermissionByToggle,
    usePrevious
  };
};
