
import React, { useState,useEffect } from "react";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
type Pass={
  GetPasswords:(e:any)=>any,
}

const EditPassword = (props:Pass) => {
  const { t } = useTranslation();
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
 const [password,setPassword]=useState("");
 const [confirmPassword,setConfirmPassword]=useState("")
 const [errorPasswords,setErrorPassword]=useState("")
 const [errorNewPassword,setErrorNewPassword]=useState("")
 const [errorConfirmPassword,setErrorConfirmPassword]=useState("")
 const btnPassword=useAppSelector((state)=>state.UserReducer.btnPasswordClicked)
 const dispatch=useAppDispatch() 
 const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => {
    setshowNewPassword(!showNewPassword);
  };
  const handleClickShowconfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };
  const handleChangePassword=(event:any)=>{
    dispatch({type:"SET_BTN_PASSWORD",payload:true})
    setPassword(event.target.value)
  }
  const handleChangeConfirmPassword=(event:any)=>{
    dispatch({type:"SET_BTN_PASSWORD",payload:true})
    setConfirmPassword(event.target.value)

  }
  useEffect(()=>{
    if( password &&confirmPassword && !errorNewPassword && !errorPasswords && !errorConfirmPassword){
      dispatch({type:"SET_ERROR_PASSWORD",payload:false})
    }
    else 
    dispatch({type:"SET_ERROR_PASSWORD",payload:true})

  },[errorNewPassword,errorConfirmPassword,errorPasswords])
const strongPassRegex=new RegExp("^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$")

useEffect(()=>{
  props.GetPasswords({first:password,second:confirmPassword})
},[password,confirmPassword])

useEffect(()=>{
  dispatch({type:"SET_BTN_PASSWORD",payload:false})
},[])
 useEffect(()=>{
  if(btnPassword){
   if(password&& password.length<8){
    setErrorPassword("")
    setErrorNewPassword(t('resetPassword.passwordLength'))
  }
    else{
      if(password && password.length>20){
        setErrorPassword("")
        setErrorNewPassword(t('resetPassword.maxLength'))
      }
      else if (!strongPassRegex.test(password)&& confirmPassword.length<=20){
        setErrorPassword("")
        setErrorNewPassword(t('resetPassword.strongPass'))
      }
      else setErrorNewPassword("")
    }
    if(confirmPassword&& confirmPassword.length<8){
    setErrorConfirmPassword(t('resetPassword.passwordConfirmLength'))
  }
  else 
  if(confirmPassword &&confirmPassword.length>20){
    setErrorConfirmPassword(t('resetPassword.maxLength'))
    setErrorPassword("")
  }
  else if (!strongPassRegex.test(confirmPassword)&& confirmPassword.length<=20){
    setErrorConfirmPassword(t('resetPassword.strongPass'))
      setErrorPassword("")

    }
    else setErrorConfirmPassword("")
  }},[password,confirmPassword,btnPassword])
  useEffect(()=>{
  if(btnPassword){
    if(!password){
    
     setErrorNewPassword(t('resetPassword.passwordRequired'))
     setErrorPassword("")

    }
     if(!confirmPassword){
      setErrorConfirmPassword(t('resetPassword.passwordConfirmRequired'))
      setErrorPassword("")

     }

    
   }},[btnPassword])
   
useEffect(()=>{

 if(password !==confirmPassword && password.length>=8 && confirmPassword.length>=8 &&  password.length<=20 && confirmPassword.length<=20 &&
  strongPassRegex.test(confirmPassword) && strongPassRegex.test(password)
  ){
    setErrorConfirmPassword("")
    setErrorNewPassword("")
    setErrorPassword(t('resetPassword.NotIdenticalPass'))

  }
    else{
    setErrorPassword("")

    }
},[password,confirmPassword])
  return (
    <div className="changePassGap">

        <div >
        <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.password")}</span>
            <OutlinedInput
                    fullWidth
                    name="password"
                    id="outlined-password-input"
                    error={errorNewPassword!==""}
                    value={password}
                    required
                    placeholder={t("Profile.password")}
                    style={{ width:"100%" }}
                    type={showNewPassword ? "text" : "password"}
                    className={"form-password-input formItem3"}
                    onChange={handleChangePassword}
                    autoComplete="new-password"
                      endAdornment={
                        <InputAdornment position="end">
                        <button
                          style={{backgroundColor:"transparent",border:"none",color:'rgba(0, 0, 0, 0.54)'}}
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                      </InputAdornment>
    
                      }
                    ></OutlinedInput>
                    {btnPassword&&errorNewPassword!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorNewPassword}</p>}

 
        </div>
        </div>
        <div >
        <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.confirmPassword")}</span>
        <OutlinedInput
                    fullWidth
                    name="confirmPassword"
                    id="outlined-password-input"
                    error={errorConfirmPassword!==""||errorPasswords!==""}
                    value={confirmPassword}
                    required
                    placeholder={t("Profile.confirmPassword")}
                    style={{ width:"100%" }}
                    type={showconfirmPassword ? "text" : "password"}
                    className={"form-password-input formItem3"}
                    onChange={handleChangeConfirmPassword}
                    autoComplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <button
                          style={{backgroundColor:"transparent",border:"none",color:'rgba(0, 0, 0, 0.54)'}}
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowconfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                      </InputAdornment>
                    }
                    ></OutlinedInput>
                                  {btnPassword&&errorConfirmPassword!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorConfirmPassword}</p>}

              {btnPassword&&errorPasswords!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorPasswords}</p>}

        </div>
        </div>
    </div>
  );
};
export default EditPassword;

