import React from "react";
import { Button, Link, Breadcrumbs } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {AddBoxOutlined , HomeOutlined} from '@mui/icons-material';
import { Hooks } from "../utils/hooks";
import { useAppSelector} from "../../../redux/hooks";
import { Header } from "@empreinte/components.header";
import AddIcon from '@mui/icons-material/Add';

const GroupHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    handleAddGroup,
    onResetEditPassword,
    onResetPermission,
    handleSubmit,
    handleEdit,
    ADDGroup,
    UpdateGroup
  } = Hooks();
  
  const showDivCondition = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup
  );
  const checkValidation = useAppSelector(
    (state:any) => state.GroupeReducer.addGroupConstraintsData
  );
  const {profiles_group_module} = useAppSelector(state =>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  return (
    <div>
          {!showDivCondition.addGroup &&
          !showDivCondition.editGroup &&
          !showDivCondition.permissionGroup &&
          (profiles_group_module?.profiles_group_post_add_action?.authorized || isSuperAdmin )? (
            <Header
                hasPermission="false"
                title={t("Profile.listOfGroup")}
                firstBtnTitle={t("Profile.add")}
                mainPageBtnAction={handleAddGroup}
                onMainPage={true}
                firstBtnIcon={<AddIcon/>}
            />
          ) : !showDivCondition.addGroup &&
            !showDivCondition.editGroup &&
            showDivCondition.permissionGroup  ? (
              <Header
              isSubmitBtnVisible
                title={t("Permissions.permissions")}
                onMainPage={false}
                arrowBackAction={onResetPermission}
                CancelBtnAction={onResetPermission}
                secondBtnTitle={t("Profile.cancel")}
                thirdBtnTitle={t("Profile.register")}
                hasPermission={"true"}
                
            />
          ) : showDivCondition.addGroup ||
          showDivCondition.editGroup ? (
              <Header
                title={showDivCondition.addGroup ? t("Profile.ajouterGroup") : t("Profile.ipAdressModifgroup")}
                onMainPage={false}
                arrowBackAction={onResetEditPassword}
                CancelBtnAction={onResetEditPassword}
                secondBtnTitle={t("Profile.cancel")}
                thirdBtnTitle={t("Profile.register")}
                addBtnAction={showDivCondition.addGroup ? ADDGroup : UpdateGroup}
                hasPermission={"true"}
                isDisabled={!checkValidation?.name || !checkValidation?.description || !checkValidation?.roles?.length || (checkValidation.description.length > 200)}
            />
          ):""}
    </div>
  );
};
export default GroupHeader;
