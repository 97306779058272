import React from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useMediaQuery } from '@material-ui/core';
import "../ip.scss"
import {useWindowSize} from './resizeEvent'


export const DataIp = () =>{
    const windowSize = useWindowSize()
    const hideColumn = () => {
       return  windowSize < 768
    };
    const hideColumn2 = () => {
        return  windowSize < 414
     };
    const showPhoneColumns = useMediaQuery('(min-width:757px)');
    const {t} = useTranslation();
    const ListTableIP = useAppSelector((state) => state.IPReducer.ListIP);
    const columns:any = [
        // {
        //     headerName: 'ID',
        //     field: 'id',
        //     key: 0,
        //     width:showPhoneColumns ? 250 : 100,
        //     flex: 1
        // },
        // {
        //     headerName: t("Profile.adresse IP"),
        //     field: 'ip',
        //     key: 1,
        //     width:showPhoneColumns ? 400 : 200,
        //     flex: 1,
        //     hide:hideColumn2()

        // },
        // {
        //     headerName: 'Description',
        //     field: 'description',
        //     key: 2,
        //     width:showPhoneColumns ? 850 : 200,
        //     flex: 1,
        //     hide:hideColumn()

        // },
    ];

    const data = {
        totalElements: 22,
        content:
        (typeof ListTableIP === 'undefined') ?  [] :  ListTableIP
      };
    
    return({
        columns,
        data
    })

}