import React, { useEffect, useState,useLayoutEffect } from "react";
import './ip.scss';
import '../user/user.scss'
import IpHeader from "./components/ipHeader";
import IpTable from "./components/List/IpTable";
import AddEditIP from "./components/AddEdit/addEdit";
import {Hooks} from "./utils/hooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getUserData } from "../../userData/action";
import {GraphQLFetchData} from "./utils/GraphQLFetchData";
import LoadingScreen from "../ip/components/loadingScreen"
import Notfound from "./components/Notfound";
import {useLocation} from 'react-router-dom'
import i18next from 'i18next';
import { useDebounce } from "../../utils/use-debounce";
const IpProfile = ({submenu} : any) =>{
    const {GetListIps}=GraphQLFetchData()
    const showDivCondition = useAppSelector((state) => state.IPReducer.showDivsConditionsIP)
    const loading = useAppSelector((state) => state.IPReducer.paginationPropsIP.Loading)
    const auth = useAppSelector((state)=> state.IPReducer.paginationPropsIP.Authorized)
    const desc=useAppSelector((state)=>state.IPReducer.paginationPropsIP.desc)
  const order=useAppSelector((state)=>state.IPReducer.paginationPropsIP.order)
    const [searchText, setSearchText] = useState("");
    const debouncedSearchQuery = useDebounce(searchText, 600);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search)
    const [lang, setLang] = useState(queryParam.get("lang") || (localStorage.getItem("lang") !== "null" && localStorage.getItem("lang")) || "fr")
    const dispatch = useAppDispatch();
    useLayoutEffect(()=>{
        dispatch(getUserData())
    },[])
    function checkString(s:string) {
        return /^[0-9]*$/.test(s);
    }
    useEffect(() => {
        if(auth){
            const offset = (currentPage - 1) * pageSize;
                if (debouncedSearchQuery) {
                    if(!checkString(searchText)){                       
                        GetListIps({
                            variables: {
                            where: {or:[{ipContains: searchText},{descriptionContains:searchText}]} ,
                            pagination: { limit: pageSize, offset:offset },
                            orderBy: {field: order, direction: desc}
                            } 
                    })}else{
                        GetListIps({
                        variables: {
                        where: {or:[{ipContains: searchText},{id:searchText}]} ,
                        pagination: { limit: pageSize, offset:offset },
                        orderBy: {field: order, direction: desc}
                        }
                    
                })}
                }else {
                GetListIps({ variables : {
                    pagination: { limit: pageSize, offset },
                    orderBy: {field: order, direction: desc}
                } });
                }    
        }
    },[currentPage, pageSize, debouncedSearchQuery,auth,desc,order]);

    useEffect(()=>{
        window.addEventListener("message", function (event) {
          if (event.data?.info === "updateLang") {
            setLang(event.data.lang)
            i18next.changeLanguage(event.data.lang)
            localStorage.setItem("lang", event.data.lang)
          } 
        });
      },[])

    return(
        <>
            {loading ? <LoadingScreen/> : auth? (
                (
                    <div className={"divGlobalTable"} >
                            {
                                !showDivCondition.addIP && !showDivCondition.editIP
                                    ?
                                    <div>
                                        <IpHeader/>
                                        <IpTable
                                            searchText={searchText}
                                            setSearchText={setSearchText}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            setPageSize={setPageSize}
                                            pageSize={pageSize}
                               
                                        />
                                    </div>
                                    : 
                                    <div>
                                        <form onSubmit={(e)=>e.preventDefault()}>
                                            <IpHeader/>
                                            <AddEditIP/>
                                        </form>
                                    </div>
                            }
    
                    </div>
                )
            ):(
                <Notfound/>
            )}
        </>
    )
}
export default IpProfile
