import { GroupList } from "./groupConstants";

const {
  showDivsConditionsGroup,
  paginationPropsGroup,
  AddGroupConstraintsData,
} = GroupList();

const GroupListINITIALSTATE:any = {
  showDivsConditionsGroup: showDivsConditionsGroup(),
  paginationPropsGroup: paginationPropsGroup(),
  addGroupConstraintsData: AddGroupConstraintsData(),
  List: [],
  ListDrop:{draw:0,recordsFiltered:0,recordsTotal:0,data:[],code:0},
  rolesSelect : [] ,
  element: {} ,
  deletedGroups:[]

};

export const GroupeReducer = (state = GroupListINITIALSTATE, action: any) => {
  switch (action.type) {    
    case "SET_DeleteGroupsActions":
      let ListwithDeletedItem = state.List.data.filter(({id} : any)=>{ 
         return !action.payload.includes(id)
        })
      return {
        ...state,
        deletedGroups: action.payload,
        List:{...state.List, data: ListwithDeletedItem,recordsFiltered: state.List.recordsFiltered - action.payload.length, recordsTotal: state.List.recordsTotal - action.payload.length},
        ListDrop: {...state.ListDrop,
          recordsFiltered: state.ListDrop.recordsFiltered - action.payload.length, 
          recordsTotal: state.ListDrop.recordsTotal - action.payload.length,
          data:ListwithDeletedItem      
        }
      };
    case "SET_PaginationPropsGroup":
      const { PaginationPropsNameChange, PaginationPropsValueChange } =
        action.payload;
      return {
        ...state,
        paginationPropsGroup: {
          ...state.paginationPropsGroup,
          [PaginationPropsNameChange]: PaginationPropsValueChange,
        },
      };
    case "SET_Restar":
      return {
        ...state,
        showDivsConditionsGroup: {
          ...state.showDivsConditionsGroup,
            "action":!state.showDivsConditionsGroup.action,
        },
      };
    case "SET_showDivsConditionsGroup":
      const { showDivsConditionsName, showDivsConditionsValue  } =
        action.payload; 
      const showDivsConditionsObj = {
        ...state.showDivsConditionsGroup,
        [showDivsConditionsName]: showDivsConditionsValue,
        
      };
      return {
        ...state,
        showDivsConditionsGroup: showDivsConditionsObj,
      };
      case "SET_showDivsConditionsGroupAllOnce":
      const showDivsConditionsObjj = {
        ...state.showDivsConditionsGroup,  
        "actionsSelected":action.payload
      }
      return {
        ...state,
        showDivsConditionsGroup: showDivsConditionsObjj,
      };
    case "SET_AddGroupActions":
      const {
        addGroupConstraintsDataNameChange,
        addGroupConstraintsDataValueChange,
      } = action.payload;
      return {
        ...state,
        addGroupConstraintsData: {
          ...state.addGroupConstraintsData,
          [addGroupConstraintsDataNameChange]:
            addGroupConstraintsDataValueChange,
        },
      };
    case "GET_LIST_PROFILE":
      return {
        ...state,
       List: action.payload.List,
       ListDrop: {id:action.payload.List.draw,recordsFiltered:action.payload.List.recordsFiltered,recordsTotal:action.payload.List.recordsTotal,data:Array.from(new Set([...state.ListDrop.data,...action.payload.List.data])),code:action.payload.List.code}
      };
      case "GET_SELECT_CHOICES":
        return {
          ...state ,
          rolesSelect : action.payload.rolesSelect,
        } ;
        case "GET_ELEMENT_GROUP":
          return {
            ...state,
           element: action.payload.element,
          };
          case "DELETE_ELEMENT_GROUP":
            return {
              ...state,
             element: state.List.filter((item : any) => item.id !== action.payload.element),
            };
      
    default: {
      return state;
    }
  }
};
