import React, { useEffect, useState } from "react";
import AvatarUpload from "./avartarUpload";
import {UploadImage} from "@empreinte/components.upload-image"
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { Card, TextField, TextareaAutosize,InputLabel, FormControl } from "@mui/material";

import {InputText} from "@empreinte/components.input-text"
import {TextArea} from "@empreinte/components.text-area"
import { useDispatch } from "react-redux";
import { editUser, getUsersData, uploadImage } from "../../../../services/userAPI";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { setURL, setVignette } from "../../store/userActions";
import { useAppSelector } from "../../../../redux/hooks";
import { Loader } from "@empreinte/components.loader";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BorderColor } from "@mui/icons-material";
import { start } from "repl";
import user from "../../../../Assets/usres.svg"
import importIcon from "../../../../Assets/import.svg"
const ProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch=useDispatch()
  const { values, handleChangeInputs,handleChangePhone,handleChangeMobile } = Hooks();
  const [currentUser,setCurrentUser]=useState<any>()
  const [url,setUrl]=useState<any>()
  const loading=useAppSelector((state)=>state.UserReducer.loading)
  const showDivConditions=useAppSelector((state)=>state.UserReducer.showDivsConditions)
  const urllink=useAppSelector((state)=>state.UserReducer.url)
  const [errorUsername,setErrorUsername]=useState("")
  const [errorEmail,setErrorEmail]=useState("")
  const [errorEmailValidation,seterrorEmailValidation]=useState("")
  const [errorPhone,setErrorPhone]=useState("")
  const [errorMobile,setErrorMobile]=useState("")
  const [errorFirstName,setErrorFirstName]=useState("")
  const [errorLastName,setErrorLastName]=useState("")
  const [erroradresse,setErroradresse]=useState("")
  const [errordescription,setErrordescription]=useState("")
  const [errorPostalCode, setErrorPostalCode] = useState("")
  const [imageDisplay, setimageDisplay] = useState("")
  
  const listUser=useAppSelector((state)=>state.UserReducer.ListUsers)
  const errorUniqueEmail=useAppSelector((state)=>state.UserReducer.errorEmail)
  const errorUniqueUsername=useAppSelector((state)=>state.UserReducer.errorUsername)

  useEffect(() => {
    if (values.description && values.description.length > 255) {
      setErrordescription(t("Profile.descriptionError"));
    } else setErrordescription("");
  }, [values.description]);

  useEffect(()=>{  
    dispatch(setVignette(currentUser?.vignette))
    handleChangePhone(currentUser?.phone)
    handleChangeMobile(currentUser?.mobile)
 return()=>{ dispatch(setVignette(""))}
  },[currentUser])
  const {handleChangeThumb}=Hooks()
  const handleFileChange=(e:any)=>{
    if(e.target.files[0].type==="image/jpeg"||e.target.files[0].type==="image/png"||e.target.files[0].type==="image/jpg"){
    const url=URL.createObjectURL(e.target.files[0])
    setUrl(url)
    let formData=new FormData()
    formData.append("empApiFileForm[thumb]", e.target.files[0]);
    formData.append("flowChunkNumber", "1");
    formData.append("flowChunkSize", e.target.files[0].size);
    formData.append("flowCurrentChunkSize", e.target.files[0].size);
    formData.append("flowTotalSize", "3569216");
    formData.append("flowIdentifier", `${e.target.files[0].size - e.target.files[0].name}`);
    formData.append("flowFilename", e.target.files[0].name);
    formData.append("flowRelativePath", e.target.files[0].name);
    formData.append("flowTotalChunks", "1");
    dispatch(setURL(url))
     uploadImage(formData).then((res)=>{
      dispatch(setVignette(res?.data?.location?.path))
    handleChangeThumb(res?.data?.location?.path)

     }).catch((error)=>{
      dispatch({type:"SET_ERROR_UPLOAD",payload:error})
     })
  }
  else {
    alert(t('Profile.incorrectFormat'))
    }
}

useEffect(()=>{
  if(! new RegExp('^[0-9]+$').test(values?.mobile) && values?.mobile){
     setErrorMobile(t('Profile.mobileNumberError'))
  }
  else
  setErrorMobile("")


}
,[values.mobile])
useEffect(()=>{
  if(values.firstName.length>255){
     setErrorFirstName(t('Profile.tooLongFirstName'))
  }
  else
  setErrorFirstName("")


}
,[values.firstName])
useEffect(()=>{
  if(values.lastName.length>255){
     setErrorLastName(t('Profile.tooLongLastName'))
  }
  else
  setErrorLastName("")


}
,[values.lastName])
useEffect(()=>{
  if(values.username && values.username.length>255){
     setErrorUsername(t('Profile.tooLongUsername'))
  }


}
,[values.username])
useEffect(() => {
  if (errorUniqueUsername) {
    setErrorUsername(errorUniqueUsername);
  }
}, [errorUniqueUsername]);
useEffect(() => {
  dispatch({ type: "SET_ERROR_USERNAME", payload: "" });
  setErrorUsername("");
}, [values.username]);

useEffect(() => {
  if (errorUniqueEmail) {
    setErrorEmail(errorUniqueEmail);
  }
}, [errorUniqueEmail]);



useEffect(() => {
  dispatch({ type: "SET_ERROR_EMAIL", payload: "" });
  setErrorEmail("");
}, [values.email]);
useEffect(()=>{
  if(!new RegExp('^[0-9]+$').test(values?.phone) && values?.phone){
       setErrorPhone(t('Profile.phoneNumberError'))
    }
    else
    setErrorPhone("")
  }


,[values.phone])
useEffect(()=>{
    if(! new RegExp('^[0-9]+$').test(values?.mobile) && values?.mobile){
       setErrorMobile(t('Profile.mobileNumberError'))
    }
    else
    setErrorMobile("")


}
,[values.mobile])


useEffect(() => {
  if (!new RegExp("^[0-9]+$").test(values?.postalCode) && values?.postalCode) {
    setErrorPostalCode(t("Profile.postalCodeError"));
  } else setErrorPostalCode("");
}, [values.postalCode]);

const validateEmail = (value: any) => {
  const errors = {email:""}
  if (!value) {
    errors.email = t("Profile.empty_email")  
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errors.email = t("Profile.valid_email")
  }
  else errors.email ="";
  seterrorEmailValidation(errors.email)
}
useEffect(()=>{
    if(values.adresse && values.adresse.length>255){
       setErroradresse(t('Profile.adresseError'))
    }
    else
    setErroradresse("")


  
},[values.adresse])
  // @ts-ignore

  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  };
  useEffect(()=>{
    if(values?.vignette?.indexOf('https') )
    {
      setimageDisplay(values?.vignette?.split('/').at(-1))
    }
  },[values.vignette])

  return (
    <div>
          <Loader isOpen={loading} />
      <Card style={{padding:"0px 9px"}} title={t("Profile.addprofilpicture")}>        
          <div className={"generalinfos"}>
            <span>{t("Profile.generalInfos")}</span>
          </div>
          <div style={{backgroundColor:"#F5F5F5"}}>
          <UploadImage
           title={t('Profile.fileChoose')}
           component="span" 
           icon={<span><img className="userUploadImage" src={values?.vignette?.length > 1 ? `https://${window.process.env.FO_CLIENT}/vignettes/profiles/${imageDisplay}`: user} 
           onError={(e:any)=>{
            e.target.onerror = null;
            e.target.src = user
           }}alt="User Icon" /></span>}
           variant="contained"
           name="vignette"
           startIcon={<span><img className="userUploadIcon" src={importIcon} alt="Import Icon" /></span>}
           handleChange={handleFileChange}
           fileURL={urllink}
           className="upload-podcast-thumb" 
           />
           </div>
          <div className={"InputDiv"}>
            <div className={"InputLablDiv"}>
              <div className={"Input"}>
                <span>{t("Profile.lastNameUser")}</span>
                <div className="required">*</div>
                <InputText
                    fullWidth
                    value={values.lastName}
                    style={{ marginTop:"5px" }}
                    className={"formItem1"}
                    handleChange={handleChangeInputs}
                    required
                    placeholder={t("Profile.lastNameUser")}
                    name="lastName"
                    ></InputText>
                    {errorLastName&& <p style={{color:"red",fontSize:"12px"}}>{errorLastName}</p>}

              </div>
              <div className={"Input"}>
                <span>{t("Profile.firstname")}</span>
                <div className="required">*</div>
                <InputText
                    fullWidth
                    value={values.firstName}
                    style={{ marginTop:"5px" }}
                    handleChange={handleChangeInputs}
                    className={"formItem1"}
                    required
                    placeholder={t("Profile.firstname")}
                    name="firstName"
                    ></InputText>
                    {errorFirstName&& <p style={{color:"red",fontSize:"12px"}}>{errorFirstName}</p>}

              </div>
            </div>

            <div className={"InputLablDiv"}>
            <div className={"Input"}>

              <span>{t("Profile.userName")}</span>
                <div className="required">*</div>
                <InputText
                    fullWidth
                    error={errorUniqueUsername&& <p style={{color:"red",fontSize:"12px"}}>{errorUniqueUsername}</p>}
                    value={values.username}
                    style={{ marginTop:"5px" }}
                    className={"formItem1"}
                    handleChange={handleChangeInputs}
                    required                                     
                    placeholder={t("Profile.userName")}
                    name="username"
                    ></InputText>
                  {errorUsername && <p style={{color:"red",fontSize:"12px"}}>{errorUsername}</p>}


            </div>
            </div>

            <div className={"InputLablDiv"}>
              <div className="Input">
                   <span>{t("Profile.email")}</span>
                    <div className="required">*</div>
                    <TextField
                    fullWidth
                    error={(errorUniqueEmail || errorEmailValidation)&& <p style={{color:"red",fontSize:"12px"}}></p>}
                    value={values.email}
                    defaultValue={values.email}
                    style={{ marginTop:"5px", color:(errorEmail|| errorEmailValidation)!=="" ? "red" : "",fontSize:"12px" }}
                    className={"formItem1"}
                    onChange={(e)=>{handleChangeInputs(e);validateEmail(e.target.value);
                    }}

                    required
                    placeholder={"Email"}
                    name="email"
                    ></TextField>
                    {(errorUniqueEmail || errorEmailValidation)&& <p style={{color:"red",fontSize:"12px"}}>{errorEmail || errorEmailValidation}</p>}


            </div>
            </div>
            <div className={"InputLablDiv"}>
             <div className="Input"> 
                   <span>{t("Profile.phone")}</span>
                   <InputText
                   error={errorPhone!==""}

                    fullWidth
                    value={values?.phone}
                    style={{ marginTop:"5px" }}
                    className={"formItem1"}
                    handleChange={handleChangeInputs}
                    placeholder={t("Profile.phone")}
                    name="phone"

  ></InputText>
                    {errorPhone!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorPhone}</p>}


              </div>
              <div className="Input">
              <span>{t("Profile.mobile")}</span>
                    <div className="required"></div>
              <InputText
                    fullWidth
                    error={errorMobile!==""}
                    value={values?.mobile}
                    style={values.mobile?{borderColor:"red 1px",marginTop:"5px"}:{ marginTop:"5px" }}
                    className={"formItem1"}
                    handleChange={handleChangeInputs}
                    required
                    placeholder={t("Profile.mobile")}
                    name="mobile"
                    inputProps={{classes:{notchedOutline:{"border-color":"red"}}}}
                    
                    ></InputText>
                    {errorMobile!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorMobile}</p>}

            </div>
           </div>
           <div className={"InputLablDiv"}>
            <div className="Input">
              <span>{t("Profile.city")}</span>
              <InputText
                fullWidth
                value={values?.city}
                style={{ marginTop: "5px" }}
                className={"formItem1"}
                handleChange={handleChangeInputs}
                placeholder={t("Profile.city")}
                name="city"

              ></InputText>


              </div>
              <div className="Input">
              <span>{t("Profile.postalCode")}</span>
              <InputText
                fullWidth
                value={values?.postalCode}
                style={values.postalCode ? { borderColor: "red 1px", marginTop: "5px" } : { marginTop: "5px" }}
                className={"formItem1"}
                handleChange={handleChangeInputs}
                required
                placeholder={t("Profile.postalCode")}
                name="postalCode"
                inputProps={{ classes: { notchedOutline: { "border-color": "red" } } }}

              ></InputText>
              {errorPostalCode !== "" && <p style={{ color: "red", fontSize: "12px" }}>{errorPostalCode}</p>}

            </div>
          </div>
          <div className={"InputLablDiv"}>
            <div className="Input">
              <span>{t("Profile.society")}</span>
              <InputText
                fullWidth
                value={values?.society}
                style={{ marginTop: "5px" }}
                className={"formItem1"}
                handleChange={handleChangeInputs}
                placeholder={t("Profile.society")}
                name="society"

              ></InputText>
            </div>
            <div className="Input">
              <span>{t("Profile.numberPerson")}</span>
              <div className="required"></div>
              <InputText

                fullWidth
                value={values?.numberPerson>=0 ?values?.numberPerson :0}
                style={values.numberPerson ? { borderColor: "red 1px", marginTop: "5px" } : { marginTop: "5px" }}
                className={"formItem1"}
                handleChange={handleChangeInputs}
                required
                placeholder={t("Profile.numberPerson")}
                name="numberPerson"
                inputProps={{ classes: { notchedOutline: { "border-color": "red" } } }}
                type="number"
                onKeyDownAction={handleKeyDown}
              ></InputText>
            </div>
          </div>
          <div className={"InputLablDiv"}>
            <div className="Input">

              <span>{t("Profile.address")}</span>
              <TextArea
                fullWidth
                name='adresse'
                error={erroradresse !== ""}
                placeholder={t('Profile.address')}
                value={values?.adresse}
                style={{ marginTop: "5px" }}
                row={2}
                maxRows={4}
                required
                handleChange={handleChangeInputs}
              ></TextArea>
              {erroradresse !== "" && <p style={{ color: "red", fontSize: "12px" }}>{erroradresse}</p>}

              <br />
            </div>
          </div>
          <div className={"InputLablDiv"}>
              <div className="Input">
                 
                   <span>{t("Profile.description")}</span>
                    <TextArea
                    fullWidth
                    name='description'
                    error={errordescription!==""}
                    placeholder={t('Profile.description')}
                    value={values?.description}
                    style={{marginTop:"5px"}}
                    row={2}
                    maxRows={4}
                    required
                    handleChange={handleChangeInputs}
                ></TextArea>
                  {errordescription!=="" && <p style={{color:"red",fontSize:"12px"}}>{errordescription}</p>}

                <br/>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default ProfileForm;
