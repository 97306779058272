import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddBoxOutlined, HomeOutlined } from "@mui/icons-material";
import { Button, Link, Breadcrumbs } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { Hooks } from "../utils/hooks";
import { Header } from "@empreinte/components.header";
import AddIcon from '@mui/icons-material/Add';
const IpHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { handleAddIp, onResetIP, AddIp, editIp,actionAddEdit } = Hooks();

  const showDivCondition = useAppSelector(
    (state) => state.IPReducer.showDivsConditionsIP
  );
  const values = useAppSelector((state) => state.IPReducer.addIpConstraintsData)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  const { profiles_ip_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  useEffect(()=>{
    actionAddEdit()
  },[values.descIP,values.ipAdress])
  return (
    <div>
      {
      (profiles_ip_module?.profiles_ip_post_add_action?.authorized || isSuperAdmin)  && 
        !showDivCondition.addIP && !showDivCondition.editIP ? (
            <Header
                hasPermission="false"
                title={t("Profile.ipAdress")}
                firstBtnTitle={t("Profile.add")}
                mainPageBtnAction={handleAddIp}
                onMainPage={true}
                firstBtnIcon={<AddIcon/>}
            />
        ) : showDivCondition.addIP && !showDivCondition.editIP ? (
            <Header
                title={t("Profile.ajouter")}
                onMainPage={false}
                arrowBackAction={onResetIP}
                CancelBtnAction={onResetIP}
                secondBtnTitle={t("Profile.cancel")}
                thirdBtnTitle={t("Profile.register")}
                addBtnAction={() => AddIp()}
                hasPermission="false"
                isDisabled={showDivCondition.action}
                
            />
        ) : !showDivCondition.addIP && showDivCondition.editIP ? (
            <Header
                title={t("Profile.ipAdressModif")}
                onMainPage={false}
                arrowBackAction={onResetIP}
                CancelBtnAction={onResetIP}
                secondBtnTitle={t("Profile.cancel")}
                thirdBtnTitle={t("Profile.register")}
                addBtnAction={() => editIp()}
                hasPermission="false"
                isDisabled={showDivCondition.action}
            />
        ) : (
          ""
        )
      }
    </div>
  );
};
export default IpHeader;
