import React, { useEffect } from "react";
import IpProfile from "./MenuProfil/ip/ip";
import User from "./MenuProfil/user/user";
import Group from "./MenuProfil/group/group";
import "./index.scss";
import "./i18n";
import { BrowserRouter ,Switch, Route,Redirect} from "react-router-dom";
function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
         {/*<Route path=""><Redirect to="/profile/User" /></Route>*/}
          <Route path="/profile/Group"><Group /> </Route> 
          <Route path="/profile/User" ><User /></Route>
          <Route path="/profile/Ip"><IpProfile /> </Route> 
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
