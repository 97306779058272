import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import {  GridValueGetterParams } from '@mui/x-data-grid';
import { useWindowSize } from "./resize";
import { useMediaQuery } from "@material-ui/core";



export const DataUsers = () => {
  const [listUsers, setUsersList] = useState([]);
  const { t } = useTranslation();
  //Pagination Props
  const values = useAppSelector((state) => state.UserReducer.listUsersProfile);
  const paginationProps = useAppSelector(
    (state) => state.UserReducer.paginationProps
  );
  var arrayOfnodes: any = [];
  const groups = useAppSelector((state)=>state.GroupeReducer.List.data)
  const windowSize = useWindowSize()


  const hideColumn = (e:number) => {
    return  windowSize < e
 };

   const showPhoneColumns = useMediaQuery('(min-width:757px)');

  const columns :any = [
    // {
    //   headerName: "ID",
    //   field: "id",
    //   key: 0,
    //   width: 150,
    // },
    // {
    //   headerName: t("Profile.userName"),
    //   field: "username",
    //   key: 1,
    //   width:showPhoneColumns ? 400 : 200,
    //   flex: 1,
    //   hide:hideColumn(500)
    // },
    // {
    //   headerName: "Email",
    //   field: "email",
    //   key: 2,
    //   width:showPhoneColumns ? 400 : 200,
    //   flex: 1,
    //   hide:hideColumn(1150)
    // },
    // {
    //   headerName: t("Profile.Groupe"),
    //   field: "group.id",
    //   key: 3,
    //   width:showPhoneColumns ? 400 : 200,
    //   flex: 1,
    //   hide:hideColumn(900),

    //   valueGetter:(params:GridValueGetterParams)=> groups?.filter((group:any)=>group.id===params.row.group?.id)[0]?.name
    // },
    // {
    //   headerName:t("Profile.lastNameUser"),
    //   field: "firstName",
    //   key: 4,
    //   width:showPhoneColumns ? 400 : 200,
    //   flex: 1,
    //   hide:hideColumn(900)    },
    // {
    //   headerName: t("Profile.firstNameUser"),

    //   field: "lastName",
    //   key: 5,
    //   width:showPhoneColumns ? 400 : 200,
    //   flex: 1,
    //   hide:hideColumn(768)    },
      
  ];

  //   const data = {
  //     totalElements: values.totalCount,
  //     content: values.edges,
  //     // enabled: conditions.clickDeleteIcon === true ? true : false
  //   };
  const data = values
  const dataContent = data;
  return {
    columns,
    data,
    dataContent,
  };
};
