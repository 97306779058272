import { Hooks } from "../../utils/hooks";
import "./addEdit.scss";
import ProfileForm from "./profileForm";
import SecurityForm from "./securityForm";
import { Header } from "@empreinte/components.header";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import {  checkValidationCode, updateUser } from "../../../../services/userAPI";
import { setLoading, setPaginationProps, setProfileActions, setshowDivsConditions } from "../../store/userActions";
import { ModalInputs } from "@empreinte/components.modal-inputs";
import CodeConfirmation from "./codeConfirmation";
const AddEditUser = () => {
  const {t}=useTranslation()
  const dispatch=useAppDispatch();
  const showModal=useAppSelector((state)=>state.UserReducer.showModalCode)
  const code =useAppSelector((state)=>state.UserReducer.codePassword)
  const {onReset,onFinish,visibleShowCodePopup,handleCancelCodePopup,GetUsersData,onFinishUpdateUser}:any=Hooks()
  
  const showDivCondition = useAppSelector((state) => state.UserReducer.showDivsConditions)
  const values:any=useAppSelector((state)=>state.UserReducer.profileConstraintsData)
  const regex=new RegExp('^[0-9]+$')
  const submittable=showDivCondition.addUser && values.connectionType===0 ?  isNaN(values.postalCode) ||  values.firstName===""|| values.lastName===""||values.username===""|| values.email==="" || values.group===""||values.group==="0" || !values.email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)||values.firstName.length>255 ||values.lastName.length>255||values.username.length>255
  || values.password===""||values.confirmPassword===""  ||(values.password.length<8) ||(values.password !== values.confirmPassword) ||(values.confirmPassword.length<8)||(values?.mobile && !regex.test(values.mobile))||(values?.phone&&!regex.test(values?.phone)) ||values.description.length>255   
  :    values.firstName==="" ||  values.lastName===""||values.username===""|| values.email==="" || values.group===""||values.group==="0"||values.firstName.length>255 ||values.lastName.length>255||values.username.length>255||!values.email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ||(values.mobile && !regex.test(values.mobile))||(values.phone&&!regex.test(values?.phone))||values?.description?.length>255 
    const submittableedit=  isNaN(values.postalCode) || values.firstName===""|| values.lastName===""||values.username===""|| values.email===""|| values.group==="" || !values.email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)|| (values.mobile && !regex.test(values.mobile))||(values.phone && !regex.test(values.phone))||values.password!==values.confirmPassword||values?.description?.length>255 || values.group===""||values.group==="0"||values.firstName.length>255 ||values.lastName.length>255||values.username.length>255
  return (
    <div>
              {showDivCondition.addUser&&!showDivCondition.editUser&&!showDivCondition.editPassword?<Header isDisabled={submittable } onMainPage={false} hasPermission="true" arrowBackAction={() => { onReset();dispatch(setPaginationProps({
                    PaginationPropsNameChange: "current",
                    PaginationPropsValueChange: 1
                  }))}} title={t('Profile.addUser')} secondBtnTitle={t('Profile.cancel')} CancelBtnAction={() => { onReset();dispatch(setPaginationProps({
                    PaginationPropsNameChange: "current",
                    PaginationPropsValueChange: 1
                  })) }}  thirdBtnTitle={t('Profile.register')} addBtnAction={()=>{onFinish()}}/>
              :showDivCondition.editUser&&!showDivCondition.addUser&&!showDivCondition.editPassword?<Header isDisabled={submittableedit} onMainPage={false} hasPermission="true" arrowBackAction={()=>{onReset(); dispatch(setPaginationProps({
                PaginationPropsNameChange: "current",
                PaginationPropsValueChange: 1
              }))}} title={t('Profile.editUser')} secondBtnTitle={t('Profile.cancel')}  CancelBtnAction={() => { onReset();;dispatch(setPaginationProps({
                PaginationPropsNameChange: "current",
                PaginationPropsValueChange: 1
              })) }} thirdBtnTitle={t('Profile.update')} addBtnAction={onFinishUpdateUser} />:""}
      <div className="divGlobal">
        <div className="Profile">
          <div className="ProfileData">
            <ProfileForm />
          </div>
          <div style={{borderLeft:"1.5px solid rgba(185, 180, 180, 0.26)",height:"auto"}}></div>

          <div className="ProfileSecurity">
            <SecurityForm />
          </div>
        </div>
      </div>
      { values.email &&  <ModalInputs
          className="modal-group-details" 
          open={showModal}
          dividers
          title={`${t("Profile.enablePassword")}`}
          onClose={()=>{handleCancelCodePopup();}}
          onCancel={()=>{handleCancelCodePopup();}}
          CancelBtnName={t("Profile.cancel")}
          ConfirmBtnName={t("Profile.register")}

          onConfirm={()=>{
           code && checkValidationCode({id:values.id,code:code}).then(res=>{dispatch({type:"SET_ERROR_CODE",payload:""})
           if(showDivCondition.editUser)
              dispatch(
              setshowDivsConditions({
                  showDivsConditionsName: "editUser",
                  showDivsConditionsValue: false,
              }))
              else
              if(showDivCondition.addUser)
              dispatch(
              setshowDivsConditions({
                  showDivsConditionsName: "addUser",
                  showDivsConditionsValue: false,
              }))
              
              dispatch({type:"SHOW_MODAL_CODE",payload:false})
              GetUsersData(10,0,"")
             

            }).catch(err=>{
            dispatch({type:"SET_ERROR_CODE",payload:t('Profile.incorrectCode')})
            })
             }}
          children={
            <CodeConfirmation />
          }
        />
}

     
    </div>
    
  );
};

export default AddEditUser;
