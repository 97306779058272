import React, { useCallback, useEffect ,useState} from "react";
import { DeleteOutlined } from "@mui/icons-material";
import useDataTableMedia from "./DataTable";
// import * as DataUser from '../../utils/Data';
import { useAppSelector } from "../../../../redux/hooks";
import { Hooks } from "../../utils/hooks";
import { setFilterVideosActions } from "../../store/userActions";
import { useDispatch } from "react-redux";
import { DataUsers } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import { FolderOpenOutlined, FolderOutlined } from "@mui/icons-material";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import {SearchBar} from "@empreinte/components.search-bar"
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import "../../user.scss"
import{
  Button,
  Tooltip,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { deleteUser, getUsersData, multiDelete } from "../../../../services/userAPI";
import { getGroups } from "../../../../services/userAPI";
import axios from "axios";
import { setLoading } from "../../store/userActions";
import { SelectList } from "@empreinte/components.select-list";
import { setPaginationProps } from "../../store/userActions";
import debounce from 'lodash.debounce';

interface Props{
  setCurrentPage:(e:any)=>any,
  currentPage:number
  setPageSize:(e:any)=>any,
  pageSize:number,
  searchKeyword:string,
  setSearchKeyword:(e:any)=>any
}
const UserTable = ({pageSize,setPageSize,currentPage,setCurrentPage,searchKeyword,setSearchKeyword}:Props) => {
  const { handleSearchRow, onChangeFolder,GetUsersFilteredDataByGroup  } = Hooks();
  const [rol,setrol] = useState<any>([])
  const listUsersProfile=useAppSelector((state)=>state.UserReducer.listUsersProfile)
  const [initialList,setInitialList]=useState<any>()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // use Selector redux
  const valuePagination = useAppSelector(
    (state) => state.UserReducer.paginationProps
  );
  const values:any = useAppSelector((state) => state.UserReducer.filterUsers);
  const { columns, data } = DataUsers();
  const {visibleMultiDeletePopup,showModalMultiDeletePopup,handleCancelMultiDeletePopup,setUsersSelectedEmpty,GetUsersData,GetUsersFilteredData,GetUsersFilteredDataAndByGroup}:any=Hooks()
  const {profiles_user_module} = useAppSelector(state =>state.ReducerUserData.modules)  
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  const groups = useAppSelector((state)=>state.GroupeReducer.List.data)
  const listUsers = useAppSelector((state)=>state.UserReducer.ListUsers)
  const order = useAppSelector((state)=>state.UserReducer.paginationProps.order)
  const desc = useAppSelector((state)=>state.UserReducer.paginationProps.desc)
  const selectedUsers = useAppSelector((state)=>state.UserReducer.selectedUsers)
  const searchgroup = useAppSelector((state)=>state.UserReducer.filterUsers.selectedGroup)
  const searchText = useAppSelector((state)=>state.UserReducer.filterUsers.search)
  // fontion pour afficher le tableau de n'interface
  const { DataTable } = useDataTableMedia();
  let dataListGroup:any=[]
  groups?.map((group:any)=>(dataListGroup.push({label:group?.name,value:group?.id})))
  dataListGroup.unshift({label:`${t('Profile.selectGroup')}`,value:0})
  const handleChangeKeyword=(event:any)=>{   
    setSearchKeyword(event.target.value)
  }
  const [selectedGroup,setSelectedGroup]=useState<any>();
  useEffect(()=>{
    setrol(values.roles)
   if(!groups) getGroups().then(res=>dispatch({type:"GET_LIST_PROFILE",payload:{List:res.data}}))
    setInitialList({data:listUsers})
  },[values.roles])

  const onChangeGroup=(event:any)=>{
    dispatch(setFilterVideosActions({
      FilterUsersNameChange: "selectedGroup",
      FilterUsersValueChange: event.target.value
  }));   
     setSelectedGroup(event?.target.value)
     dispatch(setPaginationProps({
      PaginationPropsNameChange: "current",
      PaginationPropsValueChange: 1
    }))
     if(event.target.value==="0"){
       GetUsersData(pageSize,0,searchText,order,desc)
     }
     else
     GetUsersFilteredDataAndByGroup(pageSize,0,searchText,event.target.value,order,desc)
   
  }

  useEffect(() => {
    if(searchText) setSearchKeyword(searchText)
  }, [searchText])

  useEffect(()=>{
    return ()=>  {
      setUsersSelectedEmpty()
    }
  },[])

  return (
    <div>
       
      <div className="FilterUser">
      { selectedUsers.length!==0 && 
          <div className="messageDelete">
      <IconButton
    type="submit"
    style={{marginRight:"10px"}}
    aria-label="multiDelete"
    onClick={showModalMultiDeletePopup}
    >
    <DeleteIcon  sx={{ color: "black"}}  />
    </IconButton>
    <div className="suppTextUser">{selectedUsers.length}{" "}{t("Profile.élément(s) sélectionné(s)")}</div>
          </div>
          }

        <div className={"div_rect"}>
          <div className="rect_colonne">
       <FormControl   
>
              <div className="Filter fixation fix-the-corbeiller" >
                   <SelectList
                value={selectedGroup?selectedGroup:searchgroup}
                className="selectGroup"
                style={{height:"100%"}}
                data={dataListGroup}
                handleChange={onChangeGroup}
                name="group"
                />
               </div>
            </FormControl>
          </div>
          <div className="rect_filter">
            <div className="rect_filter">
              <FormControl
              
              style={{ width: "100%"}}
            >
               <SearchBar 
                name={"search"}
                placeholder={`${t('Profile.search')}`}
                setSearchText={handleChangeKeyword}
                value={searchKeyword}
                onKeyUp={(e)=>{ 
                  dispatch(setPaginationProps({
                    PaginationPropsNameChange: "current",
                    PaginationPropsValueChange: 1
                  }))
                 
                }}
                ></SearchBar>

            </FormControl>

            </div>
          </div>
        </div>
      </div>
      {/*./Filter*/}

      {(profiles_user_module?.profiles_user_list_dtable_action?.authorized || isSuperAdmin) &&
      <div id="fixtheScroll" has-permission="profiles_user_list_dtable" className={"dataTable"}>
        <DataTable 
        setCurrentPage={(e:any)=>setCurrentPage(e)}
        currentPage={currentPage}
        setPageSize={(e:any)=>setPageSize(e)}
        pageSize={pageSize}


        />
      </div>}
      <ModalConfirm 
            className="config-ip-modal"
            open={visibleMultiDeletePopup}
            fullWidth
            onClose={handleCancelMultiDeletePopup}
            title={`${t('Profile.DeleteConfirmMessageMultiple')}?`}
            content={t("Profile.DeleteConfirmationContentUsers")}
            CancelBtnName={`${t('Profile.cancel')}`}
            onCancel={handleCancelMultiDeletePopup}
            ConfirmBtnName={`${t('Profile.confirm')}`}
            onConfirm={() => {dispatch(setLoading(true));multiDelete(selectedUsers).then((res:any)=>{handleCancelMultiDeletePopup();dispatch(setLoading(false));GetUsersFilteredDataByGroup(pageSize,(currentPage-1)*pageSize,searchgroup,order,desc);
              dispatch(setPaginationProps({
                PaginationPropsNameChange: "current",
                PaginationPropsValueChange: currentPage
              }))}).catch((err)=>console.log(err))}}
/>
    
    </div>
  );
};

export default UserTable;
