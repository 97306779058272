import React, { useEffect, useLayoutEffect, useState } from "react";
import {Tooltip,Card ,TextField ,FormControl , Switch , Checkbox ,Box, FormControlLabel,CircularProgress} from '@mui/material';
import "./permission.scss";
import { useAppSelector,useAppDispatch } from "../../../../redux/hooks";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import {SearchBar} from "@empreinte/components.search-bar"
import {Loader} from "@empreinte/components.loader"
import {
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { setshowDivsConditionsGroup } from "../../store/groupActions";
import {ModalConfirm} from "@empreinte/components.modal-confirm"

const Permission = ({setIndexes,indexes}:any) => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.allPermissions
  );
  const perByGroup = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.groupPermissions
  ); 
  const showDivsConditionsGroup = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup
  ); 
  const { selecAllCheckbox, checkedItems, handleCheckCkeckbox ,editPermissions,editOnePermissionByToggle} = Hooks();
  const [perissionss,setPermissionss] = useState<any>(permissions)
  const [search,setSearch] = useState("")
  const [search2,setSearch2] = useState("")
  const [warn,setWarn] = useState(false)
  const [opened,setOpened] = useState<string[]>([])
  const [all,setAll] = useState<string[]>([])
  const { t } = useTranslation();
  const checked = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.actionsSelected
  );
  const loading = useAppSelector(
    (state:any)=> state.GroupeReducer.showDivsConditionsGroup.loading
  )
  const rules = useAppSelector(
    (state:any)=> state.GroupeReducer.showDivsConditionsGroup.RulesPermissions
  )
  const removed = (str:any,word:any)=>{
    if(str.indexOf(word)>0){
      return str.slice(0,str.indexOf(word))
    }else{
      return str
    }
  }
useLayoutEffect(()=>{
  if( warn && !checkSameElements(checked,all)){
    selectAll(warn)
  }
},[warn])


  useEffect(()=>{
      setPermissionss(Object.keys(permissions).filter((e:any) => (e).toLowerCase().includes(search.toLowerCase()) || t(`Permissions.${e}`).toLowerCase().includes(search.toLowerCase())))
  },[search])

  useEffect(()=>{
    let bob:string[] = []
    indexes.map((e:any)=>bob.push(Object.keys(permissions)[e]))
    setOpened(bob)
  },[permissions,indexes])

  const listModule = Object.values(perissionss).map((oneModule:any,i:any)=>{
    return (
      <div  onClick={()=>{
        let index = opened.indexOf(oneModule)
        let indexindexes = indexes.indexOf(i)
        if(indexindexes < 0){
          setIndexes((prev:any)=>[...prev,i])
          setWarn(false)                /// to remove the selecteds after change the number of modules
          handleCheckCkeckbox(all,"force",true)      /// to remove the selecteds after change the number of modules
        }else{
          setIndexes((prev:any)=>prev.slice(0,indexindexes).concat(prev.slice(indexindexes+1,prev.length)))
          setWarn(false)                /// to remove the selecteds after change the number of modules
          handleCheckCkeckbox(all,"force",true)      /// to remove the selecteds after change the number of modules
        }
        }}  className={"moduleDiv"} key={i}>
        <span >{t(`Permissions.${oneModule}`)}  <i className={`arrow ${opened.indexOf(oneModule)>=0 ? "down" : "right"}`}></i></span>
      </div>
    )
  }
  );


  function checkSameElements(arr1:any, arr2:any) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      if (!arr2.includes(arr1[i])) {
        return false;
      }
    }
  
    return true;
  }

  const selectAll = (warn:boolean)=>{
    let alll:any = []
    const go = warn    
    setAll([])
      opened.map((oneModule:string,i:any)=>{
        permissions[oneModule].contents.map((action:any, j:any) => {
          alll.push(action.permission_id)
          setAll((prev)=>[...prev,action.permission_id])
        })
      })
      handleCheckCkeckbox(alll,"force",go)
   
    }
  const actionModuleAlone = search2.length >= 1 &&  Object.values(permissions).flatMap((module:any) =>
  module.contents
    .filter((e:any) =>
      e.action.name.toLowerCase().includes(search2.toLowerCase()) ||
      t(`ACTIONSLIST.${removed(e.action.name, "_action")}`).toLowerCase().includes(search2.toLowerCase())
    )
    .map((action:any, j:any) => {
   return (
    <div className={"permission_action"}>
      <div className={"actionDiv"} key={action.action.id}>
        <div> 
        
       <FormControlLabel  key={j}  name={action.action.name}  value={action.permission_id}  control={<Checkbox onChange={() => {setWarn(false);handleCheckCkeckbox([action.permission_id])}}  checked={ 
              checked.indexOf(action.permission_id) >= 0
             } />} label={t(`ACTIONSLIST.${removed(action.action.name,"_action")}`)} /> 

       </div>
       {!(perByGroup?.modules.length) ? (
         <Switch
          className={action.action.name}
          checked={false}
          onChange={(e) => editOnePermissionByToggle(action.permission_id,e.target.checked,"authorize")}
        ></Switch>
        ) : (
          <Switch
            className={action.action.name}
            checked={(perByGroup?.modules[0][module?.module]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true}
            onChange={(e) => {editOnePermissionByToggle(action.permission_id,e.target.checked,(perByGroup?.modules[0][module?.module]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true ? "unauthorize" : "authorize")
            }}
          ></Switch>
         )
       } 
      </div>
    </div>
  ); 
}))  
  const actionModule = opened.map((oneModule:string,i:any)=>{
    
    return (
      <div key={i}>
        {search2.length >= 2 ? (<></>):(
          <div className={"spanDiv"}  >
            <span className={"title_permission_action"}>{t(`Permissions.${oneModule}`)}</span>
          </div>
        )}
        {search2.length >= 2 ? (Object.values(permissions).flatMap((module:any) =>
          module.contents
            .filter((e:any) =>
              e.action.name.toLowerCase().includes(search2.toLowerCase()) ||
              t(`ACTIONSLIST.${removed(e.action.name, "_action")}`).toLowerCase().includes(search2.toLowerCase())
            )
            .map((action:any, j:any) => {
           return (
            <div className={"permission_action"}>
              <div className={"actionDiv"} key={action.action.id}>
                <div> 
                
               <FormControlLabel  key={j}  name={action.action.name}  value={action.permission_id}  control={<Checkbox onChange={() => {setWarn(false);handleCheckCkeckbox([action.permission_id])}}  checked={ 
                      checked.indexOf(action.permission_id) >= 0
                     } />} label={t(`ACTIONSLIST.${removed(action.action.name,"_action")}`)} /> 

               </div>
               {!(perByGroup?.modules.length) ? (
                 <Switch
                  className={action.action.name}
                  checked={false}
                  onChange={(e) => editOnePermissionByToggle(action.permission_id,e.target.checked,"authorize")}
                ></Switch>
                ) : (
                  <Switch
                    className={action.action.name}
                    checked={(perByGroup?.modules[0][oneModule]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true}
                    onChange={(e) => {editOnePermissionByToggle(action.permission_id,e.target.checked,(perByGroup?.modules[0][oneModule]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true ? "unauthorize" : "authorize")
                    }}
                  ></Switch>
                 )
               } 
              </div>
            </div>
          ); 
   }))  ):(
         permissions[oneModule].contents.filter((e:any)=>(e.action.name).toLowerCase().includes(search2.toLowerCase()) || t(`ACTIONSLIST.${removed(e.action.name,"_action")}`).toLowerCase().includes(search2.toLowerCase())).map((action:any, j:any) => {
           return (
            <div className={"permission_action"}>
              <div className={"actionDiv"} key={action.action.id}>
                <div> 
                
               <FormControlLabel  key={j}  name={action.action.name}  value={action.permission_id}  control={<Checkbox onChange={() => {setWarn(false);handleCheckCkeckbox([action.permission_id])}}  checked={ 
                      checked.indexOf(action.permission_id) >= 0
                     } />} label={t(`ACTIONSLIST.${removed(action.action.name,"_action")}`)} /> 

               </div>
               {!(perByGroup?.modules.length) ? (
                 <Switch
                  className={action.action.name}
                  checked={false}
                  onChange={(e) => editOnePermissionByToggle(action.permission_id,e.target.checked,"authorize")}
                ></Switch>
                ) : (
                  <Switch
                    className={action.action.name}
                    checked={(perByGroup?.modules[0][oneModule]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true}
                    onChange={(e) => {editOnePermissionByToggle(action.permission_id,e.target.checked,(perByGroup?.modules[0][oneModule]?.contents.find((e:any) => ((e.permission_id === action.permission_id)&&(e.authorized === true)))?.authorized)===true ? "unauthorize" : "authorize")
                    }}
                  ></Switch>
                 )
               } 
              </div>
            </div>
          ); 
   })
   )}
      </div>
  );
  });

  return (
    
    <div className={"permission"}>
      {rules ? (
        <ModalConfirm 
        className="config-group-modal"
        open={rules}
        fullWidth
        onClose={()=>
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "RulesPermissions",
              showDivsConditionsValue : false
            })
        )}
        title={t("Permissions.permissionactionerror")}
        ConfirmBtnName={t("Profile.confirm")}
        onConfirm={()=>
          dispatch(
            setshowDivsConditionsGroup({
              showDivsConditionsName: "RulesPermissions",
              showDivsConditionsValue : false
            })
        )}
        />
      ):(<></>)}
      <div className={"card_permission"}>
        <Card className={"listModule"}>
          <div className="checkandinput">
            <SearchBar 
              className="search-bar"
              name={"search"}
              placeholder={t("Profile.search")}
              setSearchText={(e)=>setSearch(e.target.value)}
              value={search}
            ></SearchBar>  
          </div>
          {listModule}
        </Card>
        <Card className={"card"}>
          <div className="checkandinput"> 
            <SearchBar 
            className="search-bar2"
            name={"search"}
            placeholder={t("Profile.search")}
            setSearchText={(e)=>setSearch2(e.target.value)}
            value={search2}
          ></SearchBar>
          </div>
          <div className={"divSelectAll"}>
          <FormControlLabel  control={<Checkbox className="BoldeSelectAll"  onChange={(e) =>{ setWarn(!warn);selectAll(warn)}}  checked={warn && checkSameElements(checked,all)}
                     />} label={t("Permissions.selectall")}/>   
              {warn && checkSameElements(checked,all) ? opened.length + `  Module${opened.length>1 ?"s" : "" } ${t("Permissions.selected")} !` : ""}
            <div className={"activeDesactiveDiv"}>
              <Tooltip onClick={()=>{setWarn(false);editPermissions("authorize")}} title={"Activé"}>
                <span className={"activ"}>{t("Permissions.enabled")}</span>
              </Tooltip>
              <span>/</span>
              <Tooltip onClick={()=>{setWarn(false);editPermissions("unauthorize")}} title={"Désactiver"}>
                <span className={"activ"}>{t("Permissions.disabled")}</span>
              </Tooltip>

            </div>
          </div>
          <Loader
            isOpen={loading}
          />
          {opened.length >= 1 && search2.length <= 0 ? (
            actionModule
          ):(
            actionModuleAlone
          )}
        </Card>
      </div>
    </div>
  );
};
export default Permission;
