import React, {Suspense} from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {ApolloProvider} from '@apollo/react-hooks';
import {setContext} from 'apollo-link-context';
import {Provider} from "react-redux";
import store from "./redux/store";
import './i18n';
import {Spin} from "antd";

const httpLink = createHttpLink({
    uri: window.process.env.API_HOST_DAM + "/query", 
    method: 'post',
});
const search = window.location.search;
const token = new URLSearchParams(search).get("token");
const authLink = setContext(() => {
    return {
        headers: {
            "Authorization": `Bearer ${token}`
           
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

const renderApolloProvider = () => {
    return <Suspense fallback={(<Spin className="Spin"></Spin>)}>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </Provider>
    </Suspense>
}

export default renderApolloProvider;