import React, {ChangeEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Hooks} from "../../utils/hooks";
import {useAppSelector} from "../../../../redux/hooks";
import {InputText} from "@empreinte/components.input-text"
import {TextArea} from "@empreinte/components.text-area"
import { setProfileActions } from "../../../user/store/userActions";
const AddEditIP = () => {
    const {t} = useTranslation();
     const [msgErrorIP,setMsgErrorIp] = useState("")
    const {handleChangeInputs,handleChangeInputsIdNum,isIPv4,isIPv6} = Hooks()
   const checkValue =(e : ChangeEvent<HTMLInputElement>) => {
     e.preventDefault()
    let currentValue = e.target.value;
     if(!isIPv4(currentValue) && !isIPv6(currentValue) ){           
            setMsgErrorIp(t("Profile.errorIpMsg"))          
        }
        else{
            setMsgErrorIp("");           
        }
       
        handleChangeInputsIdNum(e)
   }

    const values = useAppSelector((state) => state.IPReducer.addIpConstraintsData)  
    return(
        <div className={"divCard"}>
                <span className="generalinfosIp">{t("Profile.adressIp")}</span>
                <InputText
                    className="bordersIp"
                    fullWidth
                    error={msgErrorIP!=""}
                    defaultValue={values.ipAdress}
                    value={values.ipAdress}
                    style={{ width: '100%' }}
                    handleChange={checkValue}
                    labelInput={t("Profile.ipAdress")}
                    required
                    placeholder={t("Profile.ipAdress")}
                    name='ipAdress'
                ></InputText>
                {msgErrorIP!="" && <p style={{ color: "red", fontSize: "14px" }}>{msgErrorIP}</p>}              
                <TextArea
                    className="bordersIp"
                    fullWidth
                    name='descIP'
                    placeholder={t('Profile.description')}
                    value={values.descIP}
                    style={{ width: '100%' }}
                    row={5}
                    maxRows={10}
                    labelInput={t('Profile.description')}
                    required
                    handleChange={handleChangeInputs}
                ></TextArea>
        </div>
    )

}

export default AddEditIP
