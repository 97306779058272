import {Dispatch} from "redux"
import { addUser, deleteUser, editPassword, editUser, enableDisableUser, getUsersData, updateUser } from "../../../services/userAPI"

export function setshowUsersActions(e:any){

    return{
        type: "SET_ShowUsers",
        payload:e,
    }

}

export function setFilterVideosActions(e:any){

    return{
        type:"SET_FilterVideos",
        payload:e,
    }

}

export function setPaginationProps(e:any){
    return{
        type:"SET_PaginationProps",
        payload:e,
    }
}

export function setPaginationPropsValue(e:any){
    return{
        type:"SET_PaginationPropsValue",
        payload:e,
    }
}

export function setshowDivsConditions(e:any){
    return {
        type : "SET_showDivsConditions",
        payload:e
    }
}

export function setProfileActions(e:any){
    return{
        type:"SET_ProfileActions",
        payload:e,
    }
}


export function setEditPasswordActions(e:any){

    return{
        type:"SET_EditPasswordActions",
        payload:e
    }
}

export function setSelectedRowsUser(e:any){

    return{
        type:"SET_SELECTED_USERS",
        payload:e
    }
}
export function getPaginatedUsersData(e:any){
    return {
        type:"SET_PAGINATED_USERS_DATA",
        payload:e
    }
}
export function setLoading(e:any){
    return {
        type:"SET_LOADING",
        payload:e
    }
}
export function setVignette(e:any){
    return {
        type:"SET_VIGNETTE",
        payload:e
    }
}
export function setURL(e:any){
    return {
        type:"SET_URL",
        payload:e
    }
}
export function setBack(e:any){
    return {
        type:"SET_BACK",
        payload:e
    }
}

export const addUserAction = async(data:any,dispatch:Dispatch) => {
    
    await addUser(data)
    .then(res=>{})
    .catch(err=>{})
}
export const updateUserAction = (data:any) => async(dispatch:Dispatch)=>{
    await  updateUser(data)
    .then()
    .catch((err:any)=>console.log(err))
}
export const deleteUserAction = (id:any) => async(dispatch:Dispatch)=>{
    await deleteUser(id)
    .then()
    .catch((err:any)=>console.log(err))

}

export const  enableDisableUserAction = (action:any,id:any)=>async (dispatch:any)=>{
 await enableDisableUser(action,id)
 .then(res=>{})
 .catch(err=>console.log(err))
}
export const editPasswordAction = (id:any,data:any) => async(dispatch:Dispatch)=>{
    await editPassword(id,data)
    .then(res=>dispatch({type:"SET_EditPasswordActions",payload:data}))
    .catch(err=>console.log(err))
}