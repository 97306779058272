import {IpList} from "./ipConstants";

const {showDivsConditionsIP,paginationPropsIP , AddIPConstraintsData,FilterIP}=IpList()

const IPListINITIALSTATE = {
    showDivsConditionsIP:showDivsConditionsIP(),
    paginationPropsIP:paginationPropsIP(),
    addIpConstraintsData:AddIPConstraintsData(),
    ListIP : [],
    element: {},
    filterIP: FilterIP(),
}

export const IPReducer = (state=IPListINITIALSTATE , action:any)=>{

    switch (action.type){


        case "SET_FilterIP":
            const { FilterIPNameChange, FilterIPValueChange } = action.payload
            return {
                ...state,
                filterIP: { ...state.filterIP, [FilterIPNameChange]: FilterIPValueChange }
            }

        case "SET_PaginationPropsIP" :
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload
            return {
                ...state,
                paginationPropsIP: {...state.paginationPropsIP, [PaginationPropsNameChange]: PaginationPropsValueChange}
            }
        case "SET_showDivsConditionsGroup":
            const {showDivsConditionsName,showDivsConditionsValue}=action.payload
            const showDivsConditionsObj = {...state.showDivsConditionsIP,[showDivsConditionsName]: showDivsConditionsValue}
            return{
                ...state,
                showDivsConditionsIP:showDivsConditionsObj
            }
        case "SET_AddIPActions" :
            const {addIpConstraintsDataNameChange,addIpConstraintsDataValueChange}=action.payload
            return {
                ...state,
                addIpConstraintsData:{...state.addIpConstraintsData, [addIpConstraintsDataNameChange]:addIpConstraintsDataValueChange}
            }
            case "GET_LIST_IP":
                return {
                  ...state,
                 ListIP: action.payload.List,
                };
                case "GET_ELEMENT_IP":
          return {
            ...state,
           element: action.payload.element,
          };
          
        default: {
            return state
        }
    }

}