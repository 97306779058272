import React, { useEffect,useState ,useLayoutEffect} from "react";
import UserHeader from "./components/userHeader";
import UserTable from "./components/List/userTable";
import { Hooks } from "./utils/hooks";
import AddEditUser from "./components/AddEdit/addEdit";
import EditPassword from "./components/EditPassword/editPassword";
import "./user.scss";
import { useAppSelector,useAppDispatch } from "../../redux/hooks";
import { GraphQLFetchData } from "./utils/graphQLFetchData";
import { Grid } from "@material-ui/core";
import { getUserData } from "../../userData/action";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import LoadingScreen from "../ip/components/loadingScreen";
import { Loader } from "@empreinte/components.loader";
import { Header } from "@empreinte/components.header";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import Notfound from "../ip/components/Notfound";
import { setshowDivsConditions, setshowUsersActions } from "./store/userActions";
import { getUsersData } from "../../services/userAPI";
import { useDebounce } from "../../utils/use-debounce";

const User = () => {
  const dispatch = useAppDispatch();
  // use Selector redux
  const showDivCondition = useAppSelector(
    (state) => state.UserReducer.showDivsConditions
  );
  const {
    onFinish,
    onResetValuesAdd,
    GetUsersFilteredDataAndByGroup,
  } = Hooks();
  const auth=useAppSelector((state)=>state.UserReducer.showDivsConditions.Authorized)
  const coreRightApiCalled=useAppSelector((state)=>state.UserReducer.coreRightApiCalled)
  
  const current=useAppSelector((state)=>state.UserReducer.paginationProps.current)
  const desc=useAppSelector((state)=>state.UserReducer.paginationProps.desc)
  const order=useAppSelector((state)=>state.UserReducer.paginationProps.order)
  const [currentPage,setCurrentPage]=useState(1);
  const [pageSize,setPageSize]=useState(10)
  const loading=useAppSelector((state)=>state.UserReducer.showDivsConditions.loading)
 const searchKey=useAppSelector((state)=>state.UserReducer.filterUsers.search)
 const searchGroup=useAppSelector((state)=>state.UserReducer.filterUsers.selectedGroup)
 const location = useLocation();
 const queryParam = new URLSearchParams(location.search)
 const [lang, setLang] = useState(queryParam.get("lang") || (localStorage.getItem("lang") !== "null" && localStorage.getItem("lang")) || "fr")
  const {t}=useTranslation()
  const {handleAddUser}=Hooks()
  useLayoutEffect(()=>{
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "loading",
        showDivsConditionsValue: true,
      })
    );
    
    dispatch(getUserData())
  },[])
  useEffect(()=>{
    setCurrentPage(current)
  },[current])
  const [searchKeyword,setSearchKeyword]=useState("")
  const debouncedSearchQuery = useDebounce(searchKeyword, 600);
  useEffect(()=>{if(auth){
    const offset=(current-1) * pageSize
    GetUsersFilteredDataAndByGroup(pageSize,offset,debouncedSearchQuery,searchGroup,order,desc)
  }},[currentPage,auth,pageSize,order,desc,debouncedSearchQuery])

useEffect(()=>{
window.addEventListener("message", function (event) {
if (event.data?.info === "updateLang") {
setLang(event.data.lang)
i18next.changeLanguage(event.data.lang)
localStorage.setItem("lang", event.data.lang)
} 
});
},[])

const {profiles_user_module} = useAppSelector((state) =>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  return (
    loading?<LoadingScreen/> : auth ? (
      <div className={"divGlobalTable"}>
          {!showDivCondition.addUser && !showDivCondition.editPassword && !showDivCondition.editUser ? (
            <div>
             <Header 
             onMainPage={true}   
             title={t('Profile.UserLists')}  
             hasPermission={(profiles_user_module?.profiles_user_post_add_action?.authorized || isSuperAdmin)}
             thirdBtnTitle={t('Profile.add')} 
             mainPageBtnAction={()=>{handleAddUser();onResetValuesAdd()}}
             firstBtnTitle={t('Profile.add')}
             firstBtnIcon={<AddIcon/>}
             className='header_title'
    
            />
            {/* )} */}
              <UserTable 
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
               />
            </div>
          ): showDivCondition.editUser || showDivCondition.addUser &&!showDivCondition.editPassword?(
            <Grid
              onSubmit={onFinish}
            >
              <AddEditUser />
            </Grid>
          ):<></>}
    
        </div>):(coreRightApiCalled ?<Notfound />:<></>)
    
  )
};

export default User;

