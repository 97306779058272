import React, {useEffect} from "react";
import { Tooltip, Button, Select, MenuItem,IconButton } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import useDataTableMedia from "./DataTable";
import { DataGroupe } from "../../utils/Data";
import { useAppSelector } from "../../../../redux/hooks";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {SearchBar} from "@empreinte/components.search-bar"
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
const GroupTable = ({setSearchText, searchText, setCurrentPage,currentPage,setPageSize,pageSize}:any) => {
  const {visibleMultiDeletePopup,handleCancelMultiDeletePopup,showModalMultiDeletePopup,setGroupsSelectedEmpty,DeleteGroup}:any=Hooks()
  const { columns, data } = DataGroupe();
  const { t } = useTranslation();
  const groupsSelected = useAppSelector(
    (state:any) => state.GroupeReducer.showDivsConditionsGroup.elementsSelected
  );
  const {profiles_group_module} = useAppSelector(state =>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  const { DataTable } = useDataTableMedia({
    columns: columns,
    dataSource: data,
    updateEntityPath: "update-product",
  });
  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }
  useEffect(()=>{
return  ()=> {
setGroupsSelectedEmpty()
}
  },[])
  return (
    <div className="tableDatta" >
      <div className="rowCorbeille">
        {groupsSelected.length === 0 ? null : (
          <div className="messageDelete">
          <IconButton
            has-permission="parameters_theme_delete_multi"
            type="submit"
            aria-label="search"
            className="delete_number"
            style={{ display: "grid" }}
            onClick={showModalMultiDeletePopup}
            >
            <DeleteIcon sx={{ color: "black", marginLeft: "6px" }} />
          </IconButton>
          <div className="suppText">{groupsSelected.length}{" "}{t("Profile.élément(s) sélectionné(s)")}</div>
          </div>
        )}
      <div className="FilterGroup">
        <SearchBar 
          className="search-bar-Ip-main"
          name={"search"}
          placeholder={t("Profile.search")}
          setSearchText={handleSearch}
          value={searchText}
        ></SearchBar>
      </div> 
      </div>

     {(profiles_group_module?.profiles_group_list_dtable_action?.authorized || isSuperAdmin) && 
     <div has-permission="profiles_group_list_dtable" className={"dataTable"}>
        <DataTable
          searchText={searchText}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}  
        />
        <ModalConfirm 
            open={visibleMultiDeletePopup}
            onClose={handleCancelMultiDeletePopup}
            title={t('Profile.DeleteConfirmMessageMultipleGroup')}
            content={t("Profile.DeleteConfirmationContentText")}
            CancelBtnName={`${t('Profile.cancel')}`}
            onCancel={handleCancelMultiDeletePopup}
            ConfirmBtnName={`${t('Profile.confirm')}`}
            onConfirm={()=>{DeleteGroup(groupsSelected);handleCancelMultiDeletePopup()}}
           />
      </div>}
    </div>
  );
};
export default GroupTable;
