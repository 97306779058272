import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { setPaginationPropsIP,setshowDivsConditionsIP, setAddIPActions} from "../store/ipActions";
import { Get_allData,Add_IP,Edit_IP,Delete_IP } from "./GraphQL";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { useAppSelector } from '../../../redux/hooks';

export const GraphQLFetchData = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageSize = useAppSelector((state)=> state.IPReducer.paginationPropsIP.pageSize)
  const currentPage = useAppSelector((state)=> state.IPReducer.paginationPropsIP.current)

  const [GetListIps] = useMutation(
    Get_allData,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch({ type: "GET_LIST_IP", payload: { List: data.ips.edges } });
                dispatch(setPaginationPropsIP({
                    PaginationPropsNameChange: "totalCount",
                    PaginationPropsValueChange: data.ips.totalCount
                    
        }))
        dispatch(setPaginationPropsIP({
          PaginationPropsNameChange: "Loading",
          PaginationPropsValueChange: false
        }))
      },
      onError:(error)=>{
        message.error({ content: t('Profile.messageerroroperation'), duration: 2 })
      }
    }
  );

  const [CreateIp] = useMutation(
    Add_IP,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
            setshowDivsConditionsIP({
              showDivsConditionsName: "addIP",
              showDivsConditionsValue: false,
            })
          );
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "ipAdress",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "descIP",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setshowDivsConditionsIP({
            showDivsConditionsName: "elementsSelected",
            showDivsConditionsValue: []
        }));



        GetListIps({ variables : {
          pagination: { limit: pageSize, offset: (currentPage - 1) * pageSize }
        } });
          message.success({ content: t('Profile.successadd'), duration: 2 })  
      },
      onError:(error)=>{
        message.error({ content: t('Profile.messageerroroperation'), duration: 2 })
      }
    }
  );
  const [UpdateIp] = useMutation(
    Edit_IP,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
            setshowDivsConditionsIP({
              showDivsConditionsName: "editIP",
              showDivsConditionsValue: false,
            })
          );
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "ipAdress",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "descIP",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "Id",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setshowDivsConditionsIP({
            showDivsConditionsName: "elementsSelected",
            showDivsConditionsValue: []
        }));


        GetListIps({ variables : {
          pagination: { limit: pageSize, offset: (currentPage - 1) * pageSize }
        } });
          message.success({ content: t('Profile.successedit'), duration: 2 })  
      },
      onError:(error)=>{
        message.error({ content: t('Profile.messageerroroperation'), duration: 2 })
      }
    }
  );

  const [onDeleteIp] = useMutation(
    Delete_IP,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(setshowDivsConditionsIP({
            showDivsConditionsName: "elementsSelected",
            showDivsConditionsValue: []
        }));
        GetListIps({ variables : {
          pagination: { limit: pageSize, offset: (currentPage - 1) * pageSize }
        } });
           
      },
      onError:(error)=>{
        message.error({ content: t('Profile.messageerroroperation'), duration: 2 })
      }
    }
  );
  















  return {
    GetListIps,
    CreateIp,
    UpdateIp,
    onDeleteIp
  
  };
}
