import React, { useEffect, useState } from 'react';
// redux
import {useHistory} from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {useAppSelector} from "../../../../redux/hooks";
import UseActionMenu from "./actionsUserTable";
import {setPaginationPropsIP, setshowDivsConditionsIP} from "../../store/ipActions";
import {GridNorows} from "@empreinte/components.grid-norows"
import {Datagrid} from "@empreinte/components.datagrid"
import { useTranslation } from "react-i18next";
import { useMediaQuery } from '@material-ui/core';
import { Hooks } from '../../utils/hooks';
import {useWindowSize} from '../../utils/resizeEvent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
function useDataTableMedia({ columns, dataSource, updateEntityPath  }:any) {


    const {handelFilter,handelSearch} = Hooks()
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
  
  
    const values =  useAppSelector((state)=> state.IPReducer.filterIP)
    const total = useAppSelector((state)=> state.IPReducer.paginationPropsIP.totalCount)
    const pageSize = useAppSelector((state)=> state.IPReducer.paginationPropsIP.pageSize)
    const currentPage = useAppSelector((state)=> state.IPReducer.paginationPropsIP.current)


    const dispatch = useDispatch();
    const windowSize = useWindowSize()
    const hideColumn = () => {
       return  windowSize < 768
    };
    const hideColumn2 = () => {
        return  windowSize < 414
     };
    const showPhoneColumns = useMediaQuery('(min-width:757px)');

    const handleIconClick = (e:any, y:any) => {
        dispatch(setPaginationPropsIP({
          PaginationPropsNameChange: "order",
          PaginationPropsValueChange: e
        }))
        dispatch(setPaginationPropsIP({
        PaginationPropsNameChange: "desc",
        PaginationPropsValueChange: y
      }))
      };

    const updatedColumns = [
        {
            headerName: 'ID',
            field: 'id',
            key: 0,
            width:showPhoneColumns ? 250 : 100,
            flex: 1,
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">ID</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick("ID","ASC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick("ID","DESC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ), 
        },
        {
            headerName: t("Profile.adressIp"),
            field: 'ip',
            key: 1,
            width:showPhoneColumns ? 400 : 200,
            flex: 1,
            hide:hideColumn2(),
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("Profile.adressIp")}</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick("IP","ASC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick("IP","DESC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ), 

        },
        {
            headerName: 'Description',
            field: 'description',
            key: 2,
            width:showPhoneColumns ? 850 : 200,
            flex: 1,
            hide:hideColumn(),
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">Description</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick("DESCRIPTION","ASC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick("DESCRIPTION","DESC")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ), 

        },
        {
            headerName: 'Actions',
            key: 'action',
            field: 'action',
            renderCell: (record: any, action: any) => {
                return(
                    <UseActionMenu record={record}/>
                )
            } ,
            width:showPhoneColumns ? 300 : 200,
            flex: 1,
            sortable: false,
        },
    ];

 
    const DataTable = ({setCurrentPage,currentPage,setPageSize,pageSize}:any) => {
  
            const dataWithoutNodeKey = dataSource.content.map((item:any) => item.node);
          
            const filteredeventsfilter = handelFilter(values.type, dataWithoutNodeKey);
            const filteredevents = handelSearch(values.searchFake, filteredeventsfilter); 
                 
          
      
        return(
        <div className="DataTableIp">
        {dataSource.length === 0 ? <GridNorows content={t("Aucun enregistrement correspondant trouvé")} /> :<Datagrid 
                    rows={filteredevents}
                    columns={updatedColumns as any}
                    totalEntities={total}
                    selectionModel={selectedRowKeys}
                    checkboxSelect={(id)=>{setSelectedRowKeys(id);dispatch(
                                setshowDivsConditionsIP({
                                    showDivsConditionsName: "elementsSelected",
                                    showDivsConditionsValue: id
                                })
                            )}}
                    EntriesName={`${t("Profile.entries")}`}
                    OfPageName={`${t("Profile.of")}`}
                    ShowName={`${t("Profile.show")}`}
                    height={"calc(100vh - 185px)"}
                    setCurrentPage={(e)=>{setCurrentPage(e) ;
                        dispatch(setPaginationPropsIP({
                        PaginationPropsNameChange: "current",
                        PaginationPropsValueChange: e
                    }))}}
                    currentPage={currentPage}
                    setPageSize={(e)=>{setPageSize(e);
                        dispatch(setPaginationPropsIP({
                        PaginationPropsNameChange: "pageSize",
                        PaginationPropsValueChange: e
                    }))}}
                    pageSize={pageSize}
                    checkboxSelection
                />}
        </div>
        )
    }

    return {
        DataTable,
        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
    };
}

export default useDataTableMedia;





