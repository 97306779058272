import { TYPES } from "./types"
import axios from "axios"
import {setPaginationPropsIP} from "../MenuProfil/ip/store/ipActions"
import {setshowDivsConditionsGroup} from "../MenuProfil/group/store/groupActions"
import { setshowDivsConditions } from "../MenuProfil/user/store/userActions"
import { GetData } from "../ApiCalls"

const search = window.location.search;
const token = new URLSearchParams(search).get("token");
const headers = {
    Authorization: `Bearer ${token}`,
  }

const url = "https:"+ window.process.env.API_HOST_GLOBAL
export const getUserData = ()=> async (dispatch)=>{
    GetData(`/core/rights/get-rights-by-module`)
    .then(res=>{
      dispatch({type:TYPES.GET_USER_DATA,payload:res.data})
      if(res.data?.userData?.roles?.includes("ROLE_SUPER_ADMIN")){
        dispatch({type: TYPES.USER_IS_ADMIN,payload:true})
      }
    })
    .then(()=>
    { 
      dispatch(setPaginationPropsIP({
      PaginationPropsNameChange: "Authorized",
      PaginationPropsValueChange: true
    }));
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "Authorized",
        showDivsConditionsValue: true,
      })
    );
    dispatch(setshowDivsConditions({
      showDivsConditionsName: "Authorized",
      showDivsConditionsValue: true
    }))  
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "loading",
        showDivsConditionsValue: false,
      })
    );
  
  })
    .catch(()=>
    
    {
      dispatch(setPaginationPropsIP({
      PaginationPropsNameChange: "Loading",
      PaginationPropsValueChange: false
    }));
    dispatch(
      setshowDivsConditionsGroup({
        showDivsConditionsName: "Loading",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "loading",
        showDivsConditionsValue: false,
      })
    );

    
  })
}
