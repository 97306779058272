

export function setPaginationPropsGroup(e: any) {
  return {
    type: "SET_PaginationPropsGroup",
    payload: e,
  };
}

export function setshowDivsConditionsGroup(e: any) {
  return {
    type: "SET_showDivsConditionsGroup",
    payload: e,
  };
}
export function setshowDivsConditionsGroupp(e: any) {
  return {
    type: "SET_showDivsConditionsGroupAllOnce",
    payload: e,
  };
}

export function setAddGroupActions(e: any) {
  return {
    type: "SET_AddGroupActions",
    payload: e,
  };
}
export function setGroupsToDelete(e: any) {
  return {
    type: "SET_DeleteGroupsActions",
    payload: e,
  };
}

export function restartData(){
  return {
    type: "SET_Restar",
  };
}
