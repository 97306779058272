export const GroupList = () => {
  const paginationPropsGroup = () => {
    return {
      order: "descend",
      pageSize: 10,
      columnKey: 0,
      current: 1,
      id: [],
      idLive: 0,
    };
  };
  interface romanType {
    [key: string]: {[key: string]: [{[key:string]:any}]};
  }
  const showDivsConditionsGroup = () => {
    return {
      RulesPermissions:false,
      Authorized:false,
      Loading:true,
      loading:false,
      elementsSelected:[],
      elementSelected: 0,
      addGroup: false,
      editGroup: false,
      permissionGroup: false,
      allPermissions: {} as romanType ,
      groupPermissions: {modules:[{} as romanType]} ,
      actionsSelected: [] as string[],
      selectAll: false,
      selectOne: false,
      action:false,
    };
  };

  const AddGroupConstraintsData = () => {
    return {
      parentId:"",
      id :"" ,
      name: "",
      roles: [],
      description: "",
    };
  };

  return {
    paginationPropsGroup,
    showDivsConditionsGroup,
    AddGroupConstraintsData,

  };
};
