import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { Delete_GROUP } from "./GraphQL";
export const GraphQLFetchData = () => {
  const [onDeleteGroup] = useMutation(
    Delete_GROUP,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: () => {
           
      },
      onError:()=>{

      }
    }
  );  
  return {
    onDeleteGroup
  
  };
}