import React, { useState } from "react";
import { InputText } from "@empreinte/components.input-text";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";

const CodeConfirmation = () => {
  const {t}=useTranslation()
  const dispatch=useAppDispatch()
    const values = useAppSelector((state) => state.UserReducer.profileConstraintsData)
    const errorCode=useAppSelector((state)=>state.UserReducer.errorCode)
  const [code, setCode] = useState("");
  const handleChangeCode=(event:any)=>{
    setCode(event.target.value)
    dispatch({type:"SET_CODE",payload:event.target.value})
  }
  return (
    <div>
      <p>
      {t('Profile.codeConfirmationMessage')} "{values?.email}"
      </p>
      <br />
      {t('Profile.typeCode')} 
      <InputText
        fullWidth
        value={code}
        style={{ width: "100%" }}
        handleChange={handleChangeCode}
        required
        placeholder={"Code"}
      />
    {errorCode!==""&& <p style={{color:"red",fontSize:"12px"}}>{errorCode}</p>}

    </div>
  );
};
export default CodeConfirmation;
