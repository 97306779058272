import {combineReducers} from 'redux'
import {GroupeReducer} from "../MenuProfil/group/store/groupReducer";
import {IPReducer} from "../MenuProfil/ip/store/ipReducer";
import {UserReducer} from "../MenuProfil/user/store/userReducer";
import ReducerUserData from "../userData/reducer"

export const rootReducer = combineReducers({
    GroupeReducer,
    IPReducer,
    UserReducer,
    ReducerUserData
})

